export const adminUnlock = 'admin_unlock'
export const cleanUnlock = 'clean_unlock'
export const unlockLockers = 'unlock_lockers'
export const openLocker = 'open_locker'
export const finalizeRental = 'finalize_rental'

export const openActionLocker = 'openLocker'
export const openActionColumn = 'openColumn'
export const openActionAll = 'openAll'
export const openActionEmpty = 'openEmpty'

export const HTTPErrorCodeInvalidBookingDate = 'InvalidBookingDate'
export const HTTPErrorCodeUnavailableSizes = 'UnavailableSizes'
export const HTTPErrorCodeBookingInvalidOrExpired = 'BookingInvalidOrExpired'
export const HTTPErrorCodeBookingInvalidForPayment = 'BookingInvalidForPayment'

export const HTTPErrorCodeBookingAlreadyPaid = 'BookingAlreadyPaid'
export const HTTPErrorCodeBookingPaymentInProgress = 'BookingPaymentInProgress'
export const HTTPErrorCodeExtensionAlreadyPaid = 'ExtensionAlreadyPaid'
export const HTTPErrorCodeExtensionPaymentInProgress = 'ExtensionPaymentInProgress'
export const HTTPErrorCodeTimeExceededAlreadyPaid = 'TimeExceededAlreadyPaid'
export const HTTPErrorCodeTimeExceededPaymentInProgress = 'TimeExceededPaymentInProgress'
export const HTTPErrorCodePenaltyAlreadyPaid = 'PenaltyAlreadyPaid'
export const HTTPErrorCodePenaltyPaymentInProgress = 'PenaltyPaymentInProgress'

export const PayExceededAndExtendTimePathname = 'pay_exceeded_extend'

export const MercadoPago="MercadoPago"
export const PayPal="PayPal"
export const CreditCard="CreditCard"