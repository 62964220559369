import React, {useEffect} from "react";
import {SingleDatepicker} from "chakra-dayzed-datepicker";
import {useTranslation} from "react-i18next";

export const DatePicker = ({
                               isFromPicker = false,
                               isExtension = false,
                               minDate = new Date(),
                               maxDate = new Date(),
                               date = new Date(),
                               setDate = [] as any
                           }) => {
    useEffect(() => {
    }, [date, minDate]);

    const {t} = useTranslation('dateSelection');
    return (
        <SingleDatepicker
            /* triggerVariant="input"*/
            minDate={minDate}
            maxDate={isExtension ? maxDate : undefined}
            configs={{
                dateFormat: 'dd-MM-yyyy',
                dayNames: [
                    t('Days.Sun'),
                    t('Days.Mon'),
                    t('Days.Tue'),
                    t('Days.Wed'),
                    t('Days.Thu'),
                    t('Days.Fri'),
                    t('Days.Sat')
                ],
                monthNames: [
                    t('Months.Jan'),
                    t('Months.Feb'),
                    t('Months.Mar'),
                    t('Months.Apr'),
                    t('Months.May'),
                    t('Months.Jun'),
                    t('Months.Jul'),
                    t('Months.Aug'),
                    t('Months.Sep'),
                    t('Months.Oct'),
                    t('Months.Nov'),
                    t('Months.Dec')
                ],
            }}
            propsConfigs={{
                dateNavBtnProps: {
                    colorScheme: "secondary",
                    variant: "solid",
                },
                dayOfMonthBtnProps: {
                    defaultBtnProps: {
                        _hover: {
                            background: 'secondary.500',
                            color: "lightBrand",
                        },
                    },
                    selectedBtnProps: {
                        background: "secondary.500",
                        color: "lightBrand",
                    },
                    todayBtnProps: {
                        background: "primary.500",
                        color: "lightBrand",
                    }
                },
                inputProps: {
                    size: "md",
                    bgColor: 'primary.500',
                    _hover: {
                        background: 'secondary.500',
                    },
                    width: '100%'
                },
                triggerBtnProps: {
                    color: "lightBrand",
                    bgColor: 'secondary.500',
                    _hover: {
                        background: 'secondary.500',
                    },
                    width: '100%',
                    fontSize: 16,
                },
                popoverCompProps: {
                    popoverContentProps: {
                        background: "lightBrand",
                        color: "secondary.500",
                        ms: '-3',
                    },
                },
                calendarPanelProps: {
                    headerProps: {
                        padding: '5px',
                    },
                    dividerProps: {
                        display: "block",
                        borderColor: 'secondary.500',
                    },
                }
            }}
            name={isFromPicker ? 'from-date-picker' : 'to-date-picker'}
            date={date}
            onDateChange={setDate}
        />
    )
}