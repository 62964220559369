import {Header} from "../components/common/Header";
import {Footer} from "../components/common/Footer";
import {PayBox} from "../components/PayBox";

export const Pay = () => {
    return (
        <>
            <Header/>
            <PayBox/>
            <Footer/>
        </>
    )
}