import {Box, Center, Fade, Heading, Image, Text, VStack} from "@chakra-ui/react";
import React from "react";
import opening from "../../assets/img/opening.webp";
import {RentBtn} from "./RentBtn";
import {useTranslation} from "react-i18next";


export const Rent = () => {
    const {t} = useTranslation('landing');
    return (
        <Box position="relative" w={'100%'}>
            <Center w={'100%'}>
                <Image draggable={false} src={opening} w={'100%'}
                       height={'40vh'} objectFit="cover"/>
            </Center>
            <Box
                position="absolute"
                top={0}
                left={0}
                width={'100%'}
                height={'100%'}
                opacity={0.8} bgColor={'primary.500'}
            />
            <Fade in={true} transition={{enter: {duration: 0.5}}}>
                <VStack position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        color={'lightBrand'}
                        w={'100%'}
                        m={'auto'}
                        px={'10%'}
                        textAlign={'center'}
                        justify={'center'}>
                    <Heading textAlign={'center'} fontSize={[24, 32, 44]}>
                        {t('SloganShort')}
                    </Heading>
                    <Text fontSize={20} color={'secondary.500'}>- {t('SecurityTrustAutonomy')} -</Text>
                    <Center mt={5}>
                        <RentBtn/>
                    </Center>
                </VStack>
            </Fade>
        </Box>
    );
};