import {Box, Center, CircularProgress, Fade, Heading, Image, VStack} from "@chakra-ui/react";
import logo from "../../assets/img/lockers.webp";
import React, {useEffect, useRef, useState} from "react";

import {ExtendSlider} from "./ExtendSlider";
import {useLocation, useParams} from "react-router-dom";
import {Booking} from "../../interfaces/interfaces";
import {useFetch} from "../../hooks/useFetch";
import {HTTPErrorCodeBookingInvalidForPayment, PayExceededAndExtendTimePathname} from "../../common/constants";
import {BookingInvalidStateAlert} from "../common/BookingInvalidStateAlert";
import {BookingFinalizedAlert} from "../common/BookingFinalizedAlert";
import {validateState} from "../../helpers/states_validation";
import {useTranslation} from "react-i18next";

export const ExtendRegister = () => {
    const {t: tCommon,i18n:{language}} = useTranslation('common');
    const registerHeadingRef = useRef(null);
    const cardsRef = useRef<HTMLDivElement>(null);
    const [registerHeight, setRegisterHeight] = useState(600);
    const [activeStep, setActiveStep] = useState(0);
    const {bookingID} = useParams()
    const location = useLocation();
    const [errors, setErrors] =
        useState<Map<string, string>>(new Map());

    const [checkValidForPaymentURL] =
        useState(location.pathname.includes(PayExceededAndExtendTimePathname) ?
            '/payment/time_exceeded_extension/check/' : '/payment/extension/check/')

    const {
        data: bookingToExtend = {} as Booking,
        fetch: fetchBookingToExtend, isLoading: loadingBookingToExtend
    } = useFetch()

    const getBooking = async () => {
        const error = await fetchBookingToExtend("get", checkValidForPaymentURL + bookingID, {})

        setErrors(validateState(error?.response?.data?.Code, language))
    }

    useEffect(() => {
        getBooking()
    }, []);

    useEffect(() => {
        if (!loadingBookingToExtend && cardsRef.current) {
            if (cardsRef && cardsRef.current) {
                setRegisterHeight(cardsRef.current.getBoundingClientRect().height);
            }

            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    setRegisterHeight(entry.contentRect.height); // Update parent's height based on the active child
                }
            });

            if (cardsRef.current) {
                resizeObserver.observe(cardsRef.current); // Observe changes in the active child
            }

            return () => {
                resizeObserver.disconnect(); // Cleanup observer on unmount
            };

        }
    }, [activeStep, loadingBookingToExtend, cardsRef.current]);


    const handleActiveStep = (value: number) => {
        setActiveStep(value);
    }

    return (
        <Box id={'book_register'} position="relative" color={'lightBrand'}
             height={errors.size !== 0 ? registerHeight : registerHeight + 350} w={'100%'} transition="height 0.5s ease">
            <Center w={'100%'}>
                <Image draggable={false} src={logo} w={'100%'}
                       height={errors.size !== 0 ? registerHeight : registerHeight + 350} objectFit={'cover'}/>
            </Center>
            <Box
                position="absolute"
                top={0}
                left={0}
                width={'100%'}
                height={'100%'}
                opacity={0.8} bgColor={'primary.500'}
            />

            <Fade in={true} transition={{enter: {duration: 0.5}}}>
                <VStack
                    id={'vstack_register'}
                    position={'absolute'}
                    top={errors.size !== 0 ? 280 : 200}
                    left="50%"
                    transform="translate(-50%, -50%)"
                    textAlign="center"
                    w={'90%'}
                    justify={'center'}
                    m={'auto'}>
                    <Heading ref={registerHeadingRef} textAlign={'center'} fontSize={[30, 36, 44]} mb={4}
                             textTransform='uppercase'>
                        {tCommon('RentExtensionTitle')}
                    </Heading>
                    <VStack w={'100%'} id={'slide_container_vstack'}>
                        {
                            Array.isArray(bookingToExtend) || loadingBookingToExtend ?
                                <Center mt={10}>
                                    <CircularProgress isIndeterminate color={'secondary.500'}/>
                                </Center>
                                :
                                errors.size !== 0 && !errors.has(HTTPErrorCodeBookingInvalidForPayment) ?
                                    <BookingInvalidStateAlert errors={errors}/>
                                    :
                                    errors.has(HTTPErrorCodeBookingInvalidForPayment) ?
                                        <BookingFinalizedAlert errors={errors}/>
                                        :
                                        <ExtendSlider
                                            booking={bookingToExtend}
                                            setActiveStep={handleActiveStep}
                                            cardRef={cardsRef}
                                            registerHeadingRef={registerHeadingRef}/>
                        }
                    </VStack>
                </VStack>
            </Fade>
        </Box>
    );
};