import {SelectedSize, Size, StorageSize} from "../interfaces/interfaces";

export const isAdmin = (pathName: string) => {
    return pathName === 'admin'
}

export const roundedHrs = (minutes: number) => {
    return Math.ceil(minutes / 60);
}

export const mapToJson = (map: Map<any,any>) => {
    const obj = {} as any

    map.forEach((value, key) => {
        obj[key] = value instanceof Map ? mapToJson(value) : value;
    })

    return JSON.stringify(obj);
}

export const getSelectedSizeFromStorage = (selectedStorageSizes: StorageSize[]) :  SelectedSize[] => {
    const sizes = [] as SelectedSize[]
    for (const a of selectedStorageSizes) {
        const desc = new Map<string,string>(Object.entries(JSON.parse(a.Description)))
        const capacity = new Map<string,string>(Object.entries(JSON.parse(a.Capacity)))

        const size = {} as Size
        size.ID = a.ID
        size.Description = desc
        size.Type = a.Type
        size.Width = a.Width
        size.Height = a.Height
        size.Depth = a.Depth
        size.Price = a.Price
        size.Capacity = capacity
        size.ViewOrder = a.ViewOrder
        size.CreatedAt = a.CreatedAt
        size.UpdatedAt = a.UpdatedAt

        const sSize = {} as SelectedSize

        sSize.Size = size
        sSize.SizeQty = a.SizeQty

        sizes.push(sSize)
    }

    return sizes
}
