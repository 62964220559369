import {HStack, IconButton, Image, Menu, MenuButton, MenuItem, MenuList} from "@chakra-ui/react";
import spainFlag from "../../assets/flags/spain.webp";
import englandFlag from "../../assets/flags/england.webp";
import brasilFlag from "../../assets/flags/brasil.webp";
import {useEffect, useState} from "react";
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";

export const LanguageMenuBtn = ({closeMenu = [] as any}) => {
    const [flag, setFlag] = useState(spainFlag)
    const {i18n: {changeLanguage, language}} = useTranslation();
    const handleChangeLanguage = (lang = '') => {
        setTimeout(() => {
            changeLanguage(lang);
            closeMenu()
        }, 100)
    }

    const getFlag = (lang = '') => {
        switch (lang) {
            case 'es':
                return spainFlag
            case 'en':
                return englandFlag
            case 'pt':
                return brasilFlag
            default:
                return localStorage.getItem('i18nextLng')?.includes('en') ? englandFlag :
                localStorage.getItem('i18nextLng')?.includes('pt') ? brasilFlag :spainFlag;
        }
    }

    useEffect(() => {
        setFlag(getFlag(language))
    }, [language]);

    return (
        <Menu placement={'bottom'} flip={false} >
            <MenuButton
                as={IconButton}
                aria-label='Language'
                bgColor={'secondary.500'}
                _hover={{bgColor: 'secondary.500'}}
                _active={{bgColor: 'secondary.500'}}

            >
                <Image draggable={false} src={flag} alt='favicon' boxSize={[35,35,25]}  margin={'auto'}/>
            </MenuButton>
            <MenuList minWidth={25} border={'none'} bgColor={'secondary.500'}>
                <LanguageMenuItem text={'es'} flag={spainFlag} language={language}
                                  changeLanguage={handleChangeLanguage}/>
                <LanguageMenuItem text={'en'} flag={englandFlag} language={language}
                                  changeLanguage={handleChangeLanguage}/>
                <LanguageMenuItem text={'pt'} flag={brasilFlag} language={language}
                                  changeLanguage={handleChangeLanguage}/>
            </MenuList>
        </Menu>
    );
}

const LanguageMenuItem = ({text = '', flag = '', language = '', changeLanguage = {} as Function}) => {
    return (
        <MenuItem hidden={language.includes(text)} bgColor={'secondary.500'}
                  onClick={() => changeLanguage(text)} my={2}>
            <HStack as={motion.div} whileHover={{scale: 1.1}}>
                <Image draggable={false} src={flag} alt='favicon' boxSize={[35,35,25]}/>
            </HStack>
        </MenuItem>
    )
}