import {useToast} from "@chakra-ui/react";
import {nanoid} from "@reduxjs/toolkit";

export const useCustomToast = ({title, status}: any) => {
    const toast = useToast({
        title,
        status,
        id: nanoid(),
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        containerStyle: {
            fontSize: 22,
        }
    })

    return (toast)
}