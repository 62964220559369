import {Header} from "../components/common/Header";
import {Footer} from "../components/common/Footer";
import {RentRegister} from "../components/rent_process/RentRegister";

export const Rent = () => {
    return (
        <>
            <Header/>
            <RentRegister/>
            <Footer/>
        </>
    )
}