import {
    AspectRatio,
    Box,
    Card,
    CardBody,
    CardHeader,
    Divider,
    Heading,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {NextBtn} from "../common/NextBtn";
import {RefObject, useEffect, useState} from "react";
import {useFetch} from "../../hooks/useFetch";
import {Branch} from "../../interfaces/interfaces";
import {CustomSkeleton} from "../common/CustomSkeleton";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const branchesURL = '/branch/city'

export const Location = ({
                             cardRef = {} as RefObject<HTMLDivElement>,
                             registerBoxRef = {} as RefObject<HTMLDivElement>,
                             height = ['auto'], goToNext = [] as any
                         }) => {
    const {t} = useTranslation('location');
    const {t: tCommon} = useTranslation('common');
    const navigate = useNavigate()
    const toast = useToast()
    const {data: branches = {} as Map<string, Branch>, fetch, isLoading} = useFetch()
    const [location, setLocation] = useState(
        localStorage.getItem("location") ? localStorage.getItem("location")! : 'Ushuaia')

    const [branch, setBranch] = useState(
        localStorage.getItem("branch") ? JSON.parse(localStorage.getItem("branch")!) as Branch : {} as Branch)

    const handleLocationChange = ({target}: any) => {
        setLocation(target.value);
    }

    const handleBranchChange = (value: Branch) => {
        setBranch(value);
    }

    useEffect(() => {
        localStorage.setItem('location', location);
        localStorage.setItem('branch', JSON.stringify(branch));
    }, [location, branch]);

    useEffect(() => {
        getBranches()
    }, []);

    const getBranches = async () => {
        const error = await fetch('get', branchesURL + '/' + location, {})
        if (error) {
            if (!toast.isActive("InternalServerError")) {
                toast({
                    id: "InternalServerError",
                    title: tCommon('Errors.InternalServerError'),
                    description: tCommon('Errors.PleaseContactUs'),
                    status: 'error',
                    duration: 10000,
                    isClosable: false,
                })
            }

            navigate("/", {replace: true})

            return
        }
    }

    useEffect(() => {
        Object.entries(branches as Branch[]).map(([id, branch], index) => branch.Description === 'Rivadavia' ?
            setBranch(branch as Branch) : null)
    }, [branches]);

    // Scroll to the section when button is clicked
    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        scrollToSection(registerBoxRef)
    }, []);

    return (
        <Card
            ref={cardRef}
            bgColor={'secondary.500'} color={'lightBrand'}
            borderRadius={20} p={4} height={height}>
            <CardHeader mb={0}>
                <Heading fontSize={[22, 22, 24]}>
                    {t('Title')}
                </Heading>
            </CardHeader>
            <CardBody p={2}>
                <VStack spacing='4'>
                    <VStack spacing={4} w={'100%'}>
                        <Heading fontSize={[14, 14, 16]} w={'100%'} textAlign={'left'}
                                 textTransform='uppercase'>
                            {t('City')}
                        </Heading>
                        <CustomSkeleton isLoaded={!isLoading}>
                            <Menu matchWidth={true}>
                                <MenuButton
                                    bgColor={'secondary.500'}
                                    borderColor={'lightBrand'}
                                    outline={'none'}
                                    py={2}
                                    w={'100%'}
                                    transition='all 0.2s'
                                    borderRadius='md'
                                    borderWidth='1px'
                                    _hover={{borderColor: 'lightBrand'}}
                                    _focus={{borderColor: 'lightBrand'}}

                                >
                                    <HStack justify={'space-between'} w={'100%'} px={4}>
                                        <Text>
                                            Ushuaia
                                        </Text>
                                        <FontAwesomeIcon icon={faChevronDown}/>
                                    </HStack>
                                </MenuButton>
                                <MenuList opacity={0.8} bgColor={'secondary.500'}
                                          borderColor={'lightBrand'}
                                          overflowY={'scroll'}
                                          maxHeight={200}>
                                    <MenuItem
                                        bgColor={'secondary.500'}
                                        color={'lightBrand'}
                                        justifyContent={'center'}
                                        py={2}
                                        value={'Ushuaia'}
                                        onClick={handleLocationChange}
                                        _hover={{bgColor: 'primary.500'}}>
                                        {location}
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </CustomSkeleton>
                    </VStack>
                    <Divider/>
                    <VStack spacing={4} w={'100%'}>
                        <Heading fontSize={[14, 14, 16]} w={'100%'} textAlign={'left'}
                                 textTransform='uppercase'>
                            {t('Branch')}
                        </Heading>
                        <CustomSkeleton isLoaded={!isLoading}>
                            <Menu matchWidth={true}>
                                <MenuButton
                                    bgColor={'secondary.500'}
                                    borderColor={'lightBrand'}
                                    outline={'none'}
                                    py={2}
                                    w={'100%'}
                                    transition='all 0.2s'
                                    borderRadius='md'
                                    borderWidth='1px'
                                    _hover={{borderColor: 'lightBrand'}}
                                    _focus={{borderColor: 'lightBrand'}}
                                >
                                    <HStack justify={'space-between'} w={'100%'} px={4}>
                                        <Text>
                                            {branch.Address}
                                            {/*{branch.Description === 'Rosas' ?
                                                branch.Address + ' - ' + t('ContactUsToRent') : branch.Address}*/}
                                        </Text>
                                        <FontAwesomeIcon icon={faChevronDown}/>
                                    </HStack>
                                </MenuButton>
                                <MenuList opacity={0.8} bgColor={'secondary.500'}
                                          borderColor={'lightBrand'}
                                          overflowY={'scroll'}
                                          maxHeight={200}>
                                    {Object.entries(branches as Branch[]).map(([id, b]) => (
                                        <MenuItem
                                            key={id}
                                            bgColor={b.ID === branch.ID ? 'primary.500' : 'secondary.500'}
                                            color={'lightBrand'}
                                            justifyContent={'center'}
                                            py={2}
                                            onClick={() => handleBranchChange(b)}
                                            _hover={{bgColor: 'primary.500'}}
                                        >
                                            {b.Address}
                                            {/*{b.Description === 'Rosas' ?
                                                b.Address + ' - ' + t('ContactUsToRent') : b.Address}*/}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>
                        </CustomSkeleton>
                        <Box w={'100%'}>
                            <CustomSkeleton isLoaded={!isLoading}>
                                <AspectRatio ratio={16 / 9}>
                                    {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                                    <iframe
                                        style={{borderRadius: 10}}
                                        src={branch.MapAddress !== "" ?
                                            'https://www.google.com/maps/embed/v1/place?key=AIzaSyAwH9n8Yujc0-JyAEhFEtLg5gh5PBBSj2w&q=' + encodeURIComponent(branch?.MapAddress) :
                                            'https://www.google.com/maps/embed/v1/place?key=AIzaSyAwH9n8Yujc0-JyAEhFEtLg5gh5PBBSj2w&q=' + encodeURIComponent("V9410 Ushuaia, Tierra del Fuego")
                                        }

                                    />
                                </AspectRatio>
                            </CustomSkeleton>
                        </Box>
                    </VStack>
                    <Divider/>
                    <Spacer/>
                    <HStack w={'100%'} justifyContent={'center'} position={'relative'} bottom={0}>
                        <NextBtn isDisabled={branch.IsComingSoon} nextStep={goToNext}/>
                    </HStack>
                </VStack>
            </CardBody>
        </Card>


    )
}
