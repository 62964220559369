import {useToast} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {add, differenceInMinutes, format, parse, set, startOfToday} from "date-fns";
import {useNavigate} from "react-router-dom";
import {Branch, Discount, NewBooking, SelectedSize, StorageSize, TypeNormal} from "../../interfaces/interfaces";
import {useFetch} from "../../hooks/useFetch";
import {useTranslation} from "react-i18next";
import {getSelectedSizeFromStorage, mapToJson, roundedHrs} from "../../helpers/common";
import {CountryCode} from "../../interfaces/phone_code";
import {HTTPErrorCodeInvalidBookingDate, HTTPErrorCodeUnavailableSizes} from "../../common/constants";
import {Detail} from "../common/Detail";

const discountsURL = '/discount/all'
const newRentURL = '/rent/new'

export const RentDetail = ({
                               cardRef = {} as React.RefObject<HTMLDivElement>,
                               registerRef = {} as React.RefObject<HTMLDivElement>,
                               height = ['auto'],
                               goToPrev = [] as any
                           }) => {
    const {t: tCommon} = useTranslation('common');
    const {t, i18n: {language}} = useTranslation('detail');
    const toast = useToast()
    const navigate = useNavigate()

    const storageFromDate = localStorage.getItem('fromDate') ?
        parse(localStorage.getItem('fromDate')!.toString(), 'dd-MM-yyyy', new Date()) : null
    const storageFromTime = localStorage.getItem('fromTime') ?
        parse(localStorage.getItem('fromTime')!.toString(), 'HH:mm', new Date()) : null
    const storageToDate = localStorage.getItem('toDate') ?
        parse(localStorage.getItem('toDate')!.toString(), 'dd-MM-yyyy', new Date()) : null
    const storageToTime = localStorage.getItem('toTime') ?
        parse(localStorage.getItem('toTime')!.toString(), 'HH:mm', new Date()) : null
    const [fromDate] = useState(storageFromDate ?
        storageFromDate : startOfToday());
    const [fromTime] = useState(storageFromTime ?
        storageFromTime : set(new Date(), {seconds: 0, milliseconds: 0}));

    /* const [to, setTo] = useState(startOfToday());*/
    const [toDate] = useState(storageToDate ?
        storageToDate : startOfToday());
    const [toTime] = useState(storageToTime ?
        storageToTime : add(fromTime, {hours: 1}));

    const [from] = useState(set(fromDate,
        {hours: fromTime.getHours(), minutes: fromTime.getMinutes()}));

    const [to] = useState(set(toDate,
        {hours: toTime.getHours(), minutes: toTime.getMinutes()}));

    const [acceptTermsAndConditions] = useState(
        localStorage.getItem("acceptTermsAndConditions") ?
            localStorage.getItem("acceptTermsAndConditions")!.toString() : '');

    const [customerName] = useState(
        localStorage.getItem("customerName") ?
            localStorage.getItem("customerName")!.toString() : '')

    const [customerLastName] = useState(
        localStorage.getItem("customerLastName") ?
            localStorage.getItem("customerLastName")!.toString() : '')

    const [customerEmail] = useState(
        localStorage.getItem("customerEmail") ?
            localStorage.getItem("customerEmail")!.toString() : '')

    const [discoveryMethod] = useState(
        localStorage.getItem("discoveryMethod") ?
            localStorage.getItem("discoveryMethod")!.toString() : '')

    const [customerPhone] = useState(
        localStorage.getItem("customerPhone") ?
            localStorage.getItem("customerPhone")!.toString() : '')

    const [countryCode] = useState(
        localStorage.getItem("countryCode") ?
            JSON.parse(localStorage.getItem("countryCode")!) as CountryCode : {} as CountryCode);

    const [branch] = useState(
        localStorage.getItem('branch') ?
            JSON.parse(localStorage.getItem('branch')!) as Branch : {} as Branch)

    const [location] = useState(
        localStorage.getItem('location') ?
            localStorage.getItem('location') : 'Ushuaia')

    const [selectedStorageSizes] =
        useState(localStorage.getItem('selectedSizes') ?
            JSON.parse(localStorage.getItem('selectedSizes')!) as StorageSize[] : [] as StorageSize[])

    const [hrs] = useState(roundedHrs(differenceInMinutes(to, from)))
    const [discount, setDiscount] = useState({} as Discount)
    const [selectedSizes, setSelectedSizes] = useState([] as SelectedSize[])
    const [discountAmount, setDiscountAmount] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)

    const {
        data: discounts = {} as Map<string, Discount>,
        fetch: fetchDiscounts,
        isLoading: loadingDiscounts
    } = useFetch()

    const {fetch: fetchBookNew, isLoading: loadingBookNew} = useFetch()


    const getInitialData = async () => {
        await fetchDiscounts('get', discountsURL, {})
    }

    // Scroll to the section when button is clicked
    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'})
    };

    useEffect(() => {
        scrollToSection(registerRef)
    }, []);

    useEffect(() => {
        getInitialData()

        setSelectedSizes(getSelectedSizeFromStorage(selectedStorageSizes))
    }, []);

    useEffect(() => {
        let disc = {} as Discount
        Object.entries(discounts as Discount[]).forEach(([id, d]) => {
            if (roundedHrs(differenceInMinutes(to, from)) >= d.MinHours) {
                disc = d
            }
        })

        if (discount.ID !== '') {
            setDiscount(disc)
        }
    }, [discounts]);


    useEffect(() => {
        selectedSizes.sort((s1, s2) =>
            (s1.Size.ViewOrder <= s2.Size.ViewOrder ? -1 : 1))

        let dAmount = 0
        let total = 0
        let sTotal = 0
        selectedSizes.forEach((size, idx) => {
            let aux = roundedHrs(differenceInMinutes(to, from)) * size.SizeQty * size.Size.Price
            sTotal += aux

            if (discount.ID) {
                dAmount += aux * Number(discount.Percent / 100)

                aux = aux - aux * Number(discount.Percent / 100)
            }
            total += aux
        })

        setDiscountAmount(dAmount)
        setSubTotal(sTotal)
        setTotal(total)
    }, [selectedSizes, discount]);

    const confirm = async () => {
        const sizes = new Map<string, number>()

        for (const s of selectedSizes) {
            sizes.set(s.Size.ID, s.SizeQty)
        }
        const additionalInfo = new Map<string, string>()

        additionalInfo.set('acceptTermsAndConditions', acceptTermsAndConditions)

        const body: NewBooking = {
            From: from.toISOString(),
            To: to.toISOString(),
            SelectedSizes: JSON.parse(mapToJson(sizes)),
            BranchID: branch.ID,
            Customer: {
                Name: customerName,
                LastName: customerLastName,
                Email: customerEmail,
                Phone: countryCode.Code + customerPhone.toString(),
                DiscoveryMethod: discoveryMethod,
                Language: language,
                TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            AdditionalInfo: JSON.parse(mapToJson(additionalInfo)),
            Type: TypeNormal
        }

        const error = await fetchBookNew('post', newRentURL, body)

        if (error?.response?.data?.Code === HTTPErrorCodeUnavailableSizes) {
            toast({
                title: t('Errors.UnavailableSizes'),
                description: tCommon('Errors.IfNecessaryPleaseContactUs'),

                status: 'error',
                duration: 10000,
                isClosable: false,
            })
            return
        }

        if (error?.response?.data?.Code === HTTPErrorCodeInvalidBookingDate) {
            const f = format(fromTime, 'HH:mm') + " " + format(fromDate, 'dd-MM-yyyy')
            toast({
                title: t('Errors.InvalidBookingDate', {date: f}),
                description: tCommon('Errors.IfNecessaryPleaseContactUs'),
                status: 'error',
                duration: 10000,
                isClosable: false,
            })

            return
        }

        if (error) {
            toast({
                title: t('Errors.RentNotCreated'),
                description: tCommon('Errors.PleaseContactUs'),
                status: 'error',
                duration: 10000,
                isClosable: false,
            })

            return
        }

        toast({
            title: t('Success.RentCreated'),
            description: tCommon('Success.CheckInbox'),
            status: 'success',
            duration: 10000,
            isClosable: false,
        })

        navigate('/', {replace: true})
    }

    return (
        <Detail
            booking={{
                Location: location!,
                Branch: branch,
                From: from.toString(),
                To: to.toISOString(),
                SelectedSizes: selectedSizes,
                Customer: {
                    Name: customerName,
                    LastName: customerLastName,
                    Email: customerEmail,
                    Phone: countryCode.Code + customerPhone.toString(),
                    DiscoveryMethod: discoveryMethod,
                    Language: language,
                },
                AcceptTermsAndConditions: acceptTermsAndConditions === 'true',
            }}
            cardRef={cardRef}
            registerRef={registerRef}
            hrs={hrs}
            subTotal={subTotal}
            total={total}
            discountAmount={discountAmount}
            confirm={confirm}
            height={height}
            discount={discount}
            goToPrev={goToPrev}
            loadingConfirm={loadingBookNew}/>
    )
}