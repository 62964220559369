import {
    Box,
    Hide,
    Step,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper,
    StepSeparator,
    StepStatus,
    useSteps,
    VStack
} from "@chakra-ui/react";
import {AnimatePresence, motion} from "framer-motion";
import {RefObject, useEffect, useState} from "react";
import {ExtendSteps} from "./ExtendSteps";
import {Booking} from "../../interfaces/interfaces";


const steps = [
    {title: 'Date', description: 'Date & Time'},
    {title: 'Details', description: 'Details'},
]

const MotionBox = motion(Box);
const globalStepsHeight = ['fit-content', 'auto'];


export const ExtendSlider = ({
                                 booking = {} as Booking,
                                 setActiveStep = [] as any,
                                 cardRef = {} as RefObject<HTMLDivElement>,
                                 registerHeadingRef = {} as RefObject<HTMLDivElement>,
                                 width = ['100%', '90%', '60%', '50%', '40%', '35%'],
                                 height = globalStepsHeight
                             }) => {
    const {activeStep, goToPrevious, goToNext} = useSteps({
        index: localStorage.getItem("extendeProcessActiveStep") ?
            parseInt(localStorage.getItem("extendeProcessActiveStep")!)
            : 0
    });

    const [direction, setDirection] = useState("right");

    const slideVariants = {
        enter: (direction: string) => ({
            x: direction === "right" ? "30%" : "-30%",
            opacity: 0,
        }),
        center: {
            x: 0,
            opacity: 1,
        },
        exit: (direction: string) => ({
            x: direction === "right" ? "-30%" : "30%",
            opacity: 0,
        }),
    };

    useEffect(() => {
        localStorage.setItem("extendeProcessActiveStep", activeStep.toString())
        setActiveStep(activeStep)
    }, [activeStep]);

    const handleNext = () => {
        setDirection("right");
        goToNext();
    };

    const handlePrevious = () => {
        setDirection("left");
        goToPrevious();
    };

    return (
        <>
            <VStack width={width} id={'vstack_stepper'}>
                <Stepper size={'lg'} orientation={'horizontal'} colorScheme='secondary'
                         index={activeStep} mb={4} justifyContent={'center'} zIndex={-1} width={'50%'}>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepIndicator borderColor={'gray.300'}>
                                <StepStatus
                                    complete={<StepIcon/>}
                                    incomplete={<StepNumber/>}
                                    active={<StepNumber/>}
                                />
                            </StepIndicator>
                            <Hide below={'sm'}>
                                <StepSeparator/>
                            </Hide>
                        </Step>
                    ))}
                </Stepper>
                <Box w={'100%'} position="relative" height={height}>

                    <AnimatePresence custom={direction}>
                        {steps.map(
                            (step, index: number) =>
                                index === activeStep && (
                                    <MotionBox
                                        key={index}
                                        custom={direction}
                                        variants={slideVariants}
                                        initial="enter"
                                        animate="center"
                                        exit="exit"
                                        transition={{
                                            duration: 0.5,
                                            ease: "easeInOut",
                                        }}
                                        position="absolute"
                                        width="100%"
                                    >
                                        <ExtendSteps
                                            booking={booking}
                                            cardRef={cardRef}
                                            registerHeadingRef={registerHeadingRef}
                                            height={height}
                                            step={activeStep}
                                            goToPrev={handlePrevious}
                                            goToNext={handleNext}/>
                                    </MotionBox>
                                )
                        )}
                    </AnimatePresence>
                </Box>
            </VStack>
        </>
    );
}