import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Center,
    Collapse,
    Heading,
    HStack,
    IconButton,
    Input, Skeleton,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import {NextBtn} from "../common/NextBtn";
import {PrevBtn} from "../common/PrevBtn";
import {faCircleInfo, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useRef, useState} from "react";
import {AvailableSize, Branch, SelectedSize, Size, StorageSize} from "../../interfaces/interfaces";
import {useFetch} from "../../hooks/useFetch";
import {add, parse, set, startOfToday} from "date-fns";
import {useTranslation} from "react-i18next";
import {getSelectedSizeFromStorage, mapToJson} from "../../helpers/common";
import {CustomSkeleton} from "../common/CustomSkeleton";
import {SizeImage} from "../common/SizeImage";
import {useNavigate} from "react-router-dom";

const availableSizesURL = '/rent/available_sizes'

export const Sizes = ({
                          cardRef = {} as React.RefObject<HTMLDivElement>,
                          registerRef = {} as React.RefObject<HTMLDivElement>,
                          height = ['auto'],
                          goToPrev = [] as any,
                          goToNext = [] as any
                      }) => {
    const {t} = useTranslation('sizes');
    const {t: tCommon} = useTranslation('common');
    const navigate = useNavigate()
    const toast = useToast()
    const stackRef = useRef<HTMLDivElement>(null)
    const [skeletonHeight, setSkeletonHeight] = useState('');
    const storageFromDate = localStorage.getItem('fromDate') ?
        parse(localStorage.getItem('fromDate')!.toString(), 'dd-MM-yyyy', new Date()) : null
    const storageFromTime = localStorage.getItem('fromTime') ?
        parse(localStorage.getItem('fromTime')!.toString(), 'HH:mm', new Date()) : null
    const storageToDate = localStorage.getItem('toDate') ?
        parse(localStorage.getItem('toDate')!.toString(), 'dd-MM-yyyy', new Date()) : null
    const storageToTime = localStorage.getItem('toTime') ?
        parse(localStorage.getItem('toTime')!.toString(), 'HH:mm', new Date()) : null
    const [fromDate] = useState(storageFromDate ?
        storageFromDate : startOfToday());
    const [fromTime] = useState(storageFromTime ?
        storageFromTime : set(new Date(), {seconds: 0, milliseconds: 0}));

    /* const [to, setTo] = useState(startOfToday());*/
    const [toDate] = useState(storageToDate ?
        storageToDate : startOfToday());
    const [toTime] = useState(storageToTime ?
        storageToTime : add(fromTime, {hours: 1}));

    const [from] = useState(set(fromDate,
        {hours: fromTime.getHours(), minutes: fromTime.getMinutes()}));

    const [to] = useState(set(toDate,
        {hours: toTime.getHours(), minutes: toTime.getMinutes()}));

    const {
        data: availableSizes = {} as AvailableSize[],
        fetch: fetchAvailableSizes, isLoading: isLoadingAvailableSizes
    } = useFetch()

    const [selectedSizes, setSelectedSizes] = useState([] as SelectedSize[])
    const [selectedStorageSizes] = useState(localStorage.getItem('selectedSizes') ?
        JSON.parse(localStorage.getItem('selectedSizes')!) as StorageSize[] : [] as StorageSize[])

    const [isContinueBtnEnabled, setIsContinueBtnEnabled] = useState<boolean>(false)

    // Scroll to the section when button is clicked
    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        scrollToSection(registerRef)
    }, []);

    useEffect(() => {
        const auxList = [] as any
        for (const s of selectedSizes) {
            const desc = mapToJson(s.Size.Description!)
            const capacity = mapToJson(s.Size.Capacity!)

            const aux: {
                ID: string
                Description: string
                Type: string
                Width: number
                Height: number
                Depth: number
                Price: number
                ViewOrder: number
                Capacity: string
                CreatedAt: string
                UpdatedAt: string
                SizeQty: number
            } = {...s.Size, Description: desc, Capacity: capacity, SizeQty: s.SizeQty}

            auxList.push(aux)
        }

        localStorage.setItem('selectedSizes', JSON.stringify(auxList))
    }, [selectedSizes]);

    const handleSelectedSize = (value: SelectedSize) => {
        const newList = [] as SelectedSize[]
        newList.push(value)

        for (let i = 0; i < selectedSizes.length; i++) {
            if (selectedSizes[i].Size.ID === value.Size.ID) {
                continue
            }

            newList.push(selectedSizes[i])
        }

        setSelectedSizes(newList)
    }

    useEffect(() => {
        getInitialData()
        setSelectedSizes(getSelectedSizeFromStorage(selectedStorageSizes))
    }, []);

    const getInitialData = async () => {
        if (localStorage.getItem('branch') === null) {
            navigate("/", {replace: true})
            return
        }

        const error = await fetchAvailableSizes('post', availableSizesURL, {
            'BranchID': (JSON.parse(localStorage.getItem('branch')!) as Branch).ID,
            'From': from,
            'To': to,
        })

        if (error) {
            if (!toast.isActive("InternalServerError")) {
                toast({
                    id: "InternalServerError",
                    title: tCommon('Errors.InternalServerError'),
                    description: tCommon('Errors.PleaseContactUs'),
                    status: 'error',
                    duration: 10000,
                    isClosable: false,
                })
            }

            navigate("/", {replace: true})

            return
        }

    }

    const handleGoToPrev = () => {
        localStorage.removeItem('selectedSizes')
        goToPrev()
    }

    useEffect(() => {
    }, [availableSizes]);

    useEffect(() => {
        if (stackRef && stackRef.current) {
            setSkeletonHeight(stackRef.current.getBoundingClientRect().height.toString());
        }

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setSkeletonHeight(entry.contentRect.height.toString()); // Update parent's height based on the active child
            }
        });

        if (stackRef.current) {
            resizeObserver.observe(stackRef.current); // Observe changes in the active child
        }

        return () => {
            resizeObserver.disconnect(); // Cleanup observer on unmount
        };
    }, [isLoadingAvailableSizes]);

    useEffect(() => {
        for (const s of selectedSizes) {
            if (s.SizeQty > 0) {
                setIsContinueBtnEnabled(true)
                return
            }
        }

        setIsContinueBtnEnabled(false)
    }, [selectedSizes]);

    return (
        <Card ref={cardRef} bgColor={'secondary.500'} color={'lightBrand'}
              h={height}
              borderRadius={20} p={4} w={'100%'}>
            <CardHeader>
                <Heading fontSize={[22, 22, 24]}>
                    {t('Title')}
                </Heading>
            </CardHeader>
            <CardBody p={2}>
                <CustomSkeleton height={isLoadingAvailableSizes ? '550' : skeletonHeight}
                                isLoaded={!isLoadingAvailableSizes}>
                    <VStack spacing='4' ref={stackRef}>
                        <Accordion defaultIndex={0} width='100%'>
                            {

                                availableSizes?.map((availableSize, index) => (
                                    <SizeItem
                                        key={index}
                                        availableSize={availableSize}
                                        selectedSize={
                                            selectedSizes.find((size) =>
                                                size.Size.ID === availableSize.Size.ID)
                                        }
                                        setSelectedSize={handleSelectedSize}/>
                                ))
                            }
                        </Accordion>
                        <HStack w={'100%'} justifyContent={'center'} position={'relative'} bottom={0}>
                            <PrevBtn prevStep={handleGoToPrev}/>
                            <NextBtn isDisabled={!isContinueBtnEnabled} nextStep={goToNext}/>
                        </HStack>
                    </VStack>
                </CustomSkeleton>
            </CardBody>
        </Card>
    )
}

const SizeItem = ({
                      availableSize = {} as AvailableSize,
                      selectedSize = {} as SelectedSize,
                      setSelectedSize = [] as any
                  }) => {
    const {t, i18n: {language}} = useTranslation('sizes');

    const [quantity, setQuantity] = useState(selectedSize.SizeQty | 0);

    const [size, setSize] = useState({} as Size)

    useEffect(() => {
        const newSize = {
            ...availableSize.Size,
            Description: new Map<string, string>(Object.entries(availableSize.Size.Description!)),
            Capacity: new Map<string, string>(Object.entries(availableSize.Size.Capacity!))
        }

        setSize(newSize)
    }, [availableSize])

    useEffect(() => {
        const newSize: SelectedSize = {
            Size: size,
            SizeQty: quantity,
        }

        // this is necessary just to don't populate the SelectedSizes array with an empty Size on first render
        if (newSize.Size.ID === undefined) {
            return
        }

        setSelectedSize(newSize)

    }, [quantity]);

    useEffect(() => {
    }, []);

    return (
        <AccordionItem>
            <AccordionButton _expanded={{ bgColor: 'secondary.400', color: 'white' }}>
                <Box as='span' flex='1' textAlign='left'>
                    <HStack justify={'space-between'} w={'100%'} color={'lightBrand'}>
                        <Heading fontSize={[14, 14, 16]} textAlign={'left'}
                                 textTransform='uppercase'>
                            {size.Description?.get(language)}
                        </Heading>
                        <Badge color={'secondary.500'} borderRadius={'full'} p={1}
                               fontSize={16} w={8} textAlign={'center'}
                               me={2}>
                            {quantity}
                        </Badge>
                    </HStack>
                </Box>
                <AccordionIcon fontSize={26}/>
            </AccordionButton>
            <AccordionPanel p={0} pb={4} bgColor={'secondary.400'}>
                <Center height={300}>
                    <SizeImage type={size.Type}/>
                </Center>
                <VStack spacing={5} mb={5}>
                    <Badge p={1} px={2} fontSize={20} borderRadius={8} textAlign={'center'}
                           m={2} color={'secondary.500'}>
                        <HStack gap={1}>
                            <Text>ARS {size.Price}</Text>
                            <Text textTransform={"lowercase"}>x hr.</Text>
                        </HStack>
                    </Badge>
                    <Text fontSize={14}>
                        {size.Capacity?.get(language)}
                    </Text>
                </VStack>
                {
                    availableSize.SizeQty === 0 ?
                        <Collapse in={availableSize.SizeQty === quantity} animateOpacity>
                            <HStack color={'secondary.500'} spacing={2} w={'100%'} justify={'left'} textAlign={'left'}
                                    bg={'lightBrand'} p={3} borderRadius={6}>
                                <FontAwesomeIcon fontSize={22} icon={faCircleInfo}/>
                                <Text fontSize={[14, 16]}>
                                    {t('ZeroRemainingSizes')}
                                </Text>
                            </HStack>
                        </Collapse>
                        :
                        <>

                            <Collapse in={availableSize.SizeQty === quantity} animateOpacity>
                                <HStack spacing={2} w={'100%'} justify={'center'}>
                                    <FontAwesomeIcon fontSize={22} icon={faCircleInfo}/>
                                    <Text>
                                        {t('MaxQuantityAvailable')}
                                    </Text>
                                    <Badge color={'white'} bgColor={'primary.500'} borderRadius={'full'} p={1}
                                           fontSize={16} w={8} textAlign={'center'}
                                           me={2}>
                                        {availableSize.SizeQty}
                                    </Badge>
                                </HStack>
                            </Collapse>
                            <ButtonGroup mt={3} size='md' isAttached variant='outline' color={'lightBrand'}
                                         justifyContent={'center'}>
                                <IconButton color={'lightBrand'} aria-label='minux'
                                            _hover={{bgColor: 'secondary.500'}}
                                            onClick={() => setQuantity(quantity > 0 ? quantity - 1 : 0)}
                                            icon={<FontAwesomeIcon icon={faMinus}/>}/>
                                <Input textAlign={'center'} width={'20%'} placeholder='Cantidad' value={quantity}
                                       borderRadius={0} size={'md'} readOnly={true}/>
                                <IconButton color={'lightBrand'} aria-label='plus'
                                            _hover={{bgColor: 'secondary.500'}}
                                            onClick={() => setQuantity(
                                                availableSize.SizeQty > quantity ? quantity + 1 : quantity)}
                                            icon={<FontAwesomeIcon icon={faPlus}/>}/>
                            </ButtonGroup>
                        </>
                }
            </AccordionPanel>
        </AccordionItem>
    )
}