import {Skeleton} from "@chakra-ui/react";

export const CustomSkeleton = ({
                                   children = [] as any,
                                   height = 'auto',
                                   isLoaded = true
                               }) => (
    <Skeleton borderRadius={10}
              fitContent={true}
              startColor={'secondary.200'}
              endColor={'secondary.400'}
              isLoaded={isLoaded}
              width={'100%'}
              height={height}>
        {children}
    </Skeleton>
)
