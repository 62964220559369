import {Header} from "../components/common/Header";
import {Footer} from "../components/common/Footer";
import {ExtendRegister} from "../components/extend_process/ExtendRegister";

export const Extend = () => {
    return (
        <>
            <Header/>
            <ExtendRegister/>
            <Footer/>
        </>
    )
}