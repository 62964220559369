import {Button} from '@chakra-ui/react'
import {motion} from "framer-motion";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const RentBtn = () => {
    const navigate = useNavigate()
    const {t} = useTranslation('landing');
    return (
        <Button as={motion.button} whileHover={{scale: 1.1}} _hover={{bgColor: 'secondary.500'}}
                p={6} fontSize={20} bg={'secondary.500'} color={'lightBrand'}
                onClick={() => navigate("/rent")}>
            {t('Rent')}
        </Button>
    )
}