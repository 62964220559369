import * as React from "react"
import {ChakraProvider, extendTheme, ThemeConfig,} from "@chakra-ui/react"
import {Helmet} from 'react-helmet';

import {es} from 'date-fns/locale'
import {setDefaultOptions} from "date-fns";
import {Router} from "./router/Router";
import './i18n.ts'
import {TestingRibbon} from "./components/TestingRibbon";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

setDefaultOptions({locale: es})
const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}

// 3. extend the theme
const theme = extendTheme({
    config,
    colors: {
        primary: {
            50: '#E69175',
            100: '#E38464',
            200: '#DF7653',
            300: '#DC6841',
            400: '#D95A30',
            500: '#C64E25',
            600: '#9B3C1C',
            700: '#792F16',
            800: '#451B0D',
            900: '#220D06',
        },
        secondary: {
            50: '#0059B8',
            100: '#004FA3',
            200: '#00458F',
            300: '#003B7A',
            400: '#003166',
            500: '#002247',
            600: '#001E3D',
            700: '#001429',
            800: '#001429',
        },
        lightBrand: "#F6F7F8",
    },
    fonts: {
        heading: `'Viga', sans-serif`,
        body: `'Poppins', sans-serif`,
    }
})

declare global {
    interface Window {
        dataLayer: any[];
    }
}

export const App = () => {
    const env = process.env.REACT_APP_ENVIRONMENT
    const {t: tCommon, i18n: {language}} = useTranslation('common');
    localStorage.setItem("version", `${process.env.REACT_APP_VERSION}`)

    useEffect(() => {
        if ( env !== "prod") {
            return
        }

        const script = document.createElement('script');
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-JQYD1P4VVD";
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        function gtag(...args: any) {
            window.dataLayer.push(args);
        }
        gtag('js', new Date());
        gtag('config', 'G-JQYD1P4VVD', { send_page_view: true });
    }, []);

    return (
        <ChakraProvider theme={theme}>
            {
                env === "prod" ?
                    <Helmet>
                        <title>{tCommon('WebTitle')}</title>

                        <link rel="alternate" href="https://ulocker.ar?lang=es" hrefLang="es"/>
                        <link rel="alternate" href="https://ulocker.ar?lang=en" hrefLang="en"/>
                        <link rel="alternate" href="https://ulocker.ar?lang=pt" hrefLang="pt"/>

                        <meta name="description" content={tCommon('WebDescription')}/>
                        <meta property="og:title" content={tCommon('WebTitle')}/>
                        <meta property="og:description"
                              content={tCommon('WebDescription')}/>
                        <meta property="og:image" content="https://ulocker.ar/logo512.png"/>
                        <meta property="og:url" content="https://ulocker.ar"/>
                        <meta http-equiv="Content-Language" content={language}/>
                    </Helmet>
                    :
                    <Helmet>
                        <title>U LOCKER</title>
                    </Helmet>
            }
            {
                env === "dev" ?
                    <TestingRibbon/>
                    : []
            }
            <Router/>
        </ChakraProvider>
    )
}
