import {Header} from "../components/common/Header";
import {Footer} from "../components/common/Footer";
import {ExceededRegister} from "../components/exceeded_process/ExceededRegister";

export const Exceeded = () => {
    return (
        <>
            <Header/>
            <ExceededRegister/>
            <Footer/>
        </>
    )
}