
import { useEffect, useState } from 'react';
// @ts-ignore
import UAParser from 'ua-parser-js';
import {ClientInfo} from "../interfaces/interfaces";

export const useClientInfo = () => {
    const [clientInfo, setClientInfo] = useState({} as ClientInfo);

    useEffect(() => {
        const parser = new UAParser();
        const result = parser.getResult();

        setClientInfo({
            Browser: result.browser.name,
            BrowserVersion: result.browser.version,
            OS: result.os.name,
            OSVersion: result.os.version,
            DeviceType: result.device.type || "Desktop",
            DeviceModel: result.device.model || "Unknown",
        });
    }, []);

    return clientInfo;
};