import {
    AspectRatio,
    Badge,
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Center,
    Divider,
    Fade,
    Heading,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    VStack,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {motion} from "framer-motion";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {Branch, Size} from "../../interfaces/interfaces";
import {useFetch} from "../../hooks/useFetch";
import React, {useEffect, useRef, useState} from "react";
import {CustomSkeleton} from "../common/CustomSkeleton";
import {SizeImage} from "../common/SizeImage";
import {useTranslation} from "react-i18next";

const branchesUrl = '/branch/city'
const sizesUrl = '/size/enabled'

export const BranchesAndSizes = () => {
    const {
        data: branches = {} as Map<string, Branch>,
        fetch: fetchBranches,
        isLoading: isLoadingBranches
    } = useFetch()
    const {
        data: sizes = [] as Size[],
        fetch: fetchSizes,
        isLoading: isLoadingSizes
    } = useFetch()

    const {t} = useTranslation('landing');
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [location] = useState('Ushuaia')
    const [branch, setBranch] = useState({} as Branch)

    const navigate = useNavigate()

    useEffect(() => {
        getBranches()
        getSizes()
    }, []);

    const getBranches = async () => {
        await fetchBranches('get', branchesUrl + '/' + location, {})
    }

    const getSizes = async () => {
        await fetchSizes('get', sizesUrl, {})
    }

    useEffect(() => {
        Object.entries(branches as Branch[]).map(([id, branch], index) => index === 0 ?
            setBranch(branch as Branch) : null)
    }, [branches]);

    const handleBranchChange = (value: Branch) => {
        setBranch(value);
    }

    const handleOpenSizesModal = () => {
        onOpen()
    }

    return (
        <>
            <Wrap position={'relative'} color={'lightBrand'} w={'100%'} bgColor={'secondary.500'}
                  justify='space-evenly' align='center' py={42}>
                <WrapItem>
                    <Fade in={true} transition={{enter: {duration: 0.5}}}>
                        <Center>
                            <VStack spacing={5} align={'center'} w={['80%', '100%']}>
                                <Heading fontSize={30} textAlign={'center'} fontWeight={'bold'} color={'primary.500'}>
                                    {t('IsSimple')}
                                </Heading>
                                <Divider bgColor={'primary.500'} height='1px' borderColor={'transparent'}
                                         borderRadius={5}/>
                                <HStack spacing={5} justify={'space-around'} w={'100%'}>
                                    <Button as={motion.button} whileHover={{scale: 1.1}}
                                            _hover={{bgColor: 'primary.500'}}
                                            bg={'primary.500'} color={'lightBrand'}
                                            onClick={() => navigate("/rent")} w={'100%'}>
                                        {t('Rent')}
                                    </Button>
                                    <Button as={motion.button} whileHover={{scale: 1.1}}
                                            _hover={{bgColor: 'primary.500'}}
                                            bg={'primary.500'} color={'lightBrand'} w={'100%'}
                                            onClick={handleOpenSizesModal}>
                                        {t('Sizes')}
                                    </Button>
                                </HStack>
                            </VStack>
                        </Center>
                    </Fade>
                </WrapItem>
                <WrapItem mt={[10, 10, 10, 0]}>
                    <Fade in={true} transition={{enter: {duration: 0.5}}}>
                        <VStack spacing={4} w={'100%'}>
                            <Heading size={'md'} w={'100%'} textAlign={'left'} textTransform='uppercase'>
                                {t('Branches')}
                            </Heading>
                            <Menu matchWidth={true}>
                                <MenuButton
                                    bgColor={'secondary.500'}
                                    borderColor={'lightBrand'}
                                    outline={'none'}
                                    py={2}
                                    w={'100%'}
                                    transition='all 0.2s'
                                    borderRadius='md'
                                    borderWidth='1px'
                                    _hover={{borderColor: 'lightBrand'}}
                                    _focus={{borderColor: 'lightBrand'}}
                                >
                                    <HStack justify={'space-between'} w={'100%'} px={4}>
                                        <Text>
                                            {branch.Address}
                                        </Text>
                                        <FontAwesomeIcon icon={faChevronDown}/>
                                    </HStack>
                                </MenuButton>
                                <MenuList opacity={0.8} bgColor={'secondary.500'} borderColor={'lightBrand'}>
                                    {Object.entries(branches as Branch[]).map(([id, b]) => (
                                        <MenuItem
                                            key={id}
                                            bgColor={b.ID === branch.ID ? 'primary.500' : 'secondary.500'}
                                            color={'lightBrand'}
                                            py={2}
                                            justifyContent={'center'}
                                            onClick={() => handleBranchChange(b)}
                                            _hover={{bgColor: 'primary.500'}}>
                                            {b.Address}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>

                            <Box mt={5} w={[300, 400, 400, 500]}>
                                <CustomSkeleton isLoaded={!isLoadingBranches}>
                                    <AspectRatio ratio={16 / 9}>
                                        <iframe
                                            style={{borderRadius: 10}}
                                            src={branch.MapAddress !== "" ?
                                                'https://www.google.com/maps/embed/v1/place?key=AIzaSyAwH9n8Yujc0-JyAEhFEtLg5gh5PBBSj2w&q=' + encodeURIComponent(branch?.MapAddress) :
                                                'https://www.google.com/maps/embed/v1/place?key=AIzaSyAwH9n8Yujc0-JyAEhFEtLg5gh5PBBSj2w&q=' + encodeURIComponent("V9410 Ushuaia, Tierra del Fuego")
                                            }
                                        />
                                    </AspectRatio>
                                </CustomSkeleton>
                            </Box>
                        </VStack>
                        <Center>
                        </Center>
                    </Fade>
                </WrapItem>
            </Wrap>
            <SizesModal isLoading={isLoadingSizes} onClose={onClose} sizes={sizes} isOpen={isOpen}/>
        </>
    )
};

const SizesModal = ({
                        isLoading = false,
                        sizes = [] as Size[],
                        isOpen = false,
                        onClose = [] as any
                    }) => {
    const {t} = useTranslation('landing');
    const modalContentRef = useRef<HTMLDivElement>(null)
    const [skeletonHeight, setSkeletonHeight] = useState('');

    useEffect(() => {
        if (modalContentRef && modalContentRef.current) {
            setSkeletonHeight(modalContentRef.current.getBoundingClientRect().height.toString());
        }

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setSkeletonHeight(entry.contentRect.height.toString()); // Update parent's height based on the active child
            }
        });

        if (modalContentRef.current) {
            resizeObserver.observe(modalContentRef.current); // Observe changes in the active child
        }

        return () => {
            resizeObserver.disconnect(); // Cleanup observer on unmount
        };
    }, [isLoading]);

    return (
        <Modal scrollBehavior={'inside'} isOpen={isOpen} onClose={onClose}
               size={['sm', 'md', 'lg', 'xl']} isCentered>
            <ModalOverlay/>
            <CustomSkeleton height={isLoading ? '550' : skeletonHeight}
                            isLoaded={!isLoading}>
                <ModalContent ref={modalContentRef} bgColor={'secondary.500'} color={'lightBrand'} borderRadius={20}>
                    <ModalHeader>
                        <Heading size={'lg'} textAlign={'center'}>
                            {t('OurSizes')}
                        </Heading>
                    </ModalHeader>
                    <ModalBody textAlign={'justify'} borderColor={'gray'}>
                        {sizes.length > 0 ? sizes.map((s: Size, idx) => (
                            <SizeItem key={idx} size={s}/>
                        )) : []}
                    </ModalBody>
                    <ModalFooter my={3}>
                        <Button onClick={onClose}>Cerrar</Button>
                    </ModalFooter>
                </ModalContent>
            </CustomSkeleton>
        </Modal>
    )
}

const SizeItem = ({
                      size: fetchedSize = {} as Size
                  }) => {
    const {i18n: {language}} = useTranslation();
    const [size, setSize] = useState({} as Size);

    useEffect(() => {
        const newSize = {
            ...fetchedSize,
            Description: new Map<string, string>(Object.entries(fetchedSize.Description!)),
            Capacity: new Map<string, string>(Object.entries(fetchedSize.Capacity!))
        }

        setSize(newSize)
    }, [fetchedSize])

    return (
        <>
            <Card bgColor={'secondary.500'} color={'lightBrand'}
                  borderRadius={20} w={'100%'}>
                <CardHeader textAlign={'center'}>
                    <Heading fontSize={[22, 22, 24]} textTransform={'uppercase'}>
                        {size.Description?.get(language)}
                    </Heading>
                </CardHeader>
                <CardBody p={2}>
                    <Center height={300}>
                        <SizeImage type={size.Type}/>
                    </Center>
                    <VStack spacing={5} mb={5} justify={'center'}>
                        <Badge p={1} px={2} fontSize={20} borderRadius={8} textAlign={'center'}
                               m={2} color={'secondary.500'}>
                            <HStack gap={1}>
                                <Text>ARS {size.Price}</Text>
                                <Text textTransform={"lowercase"}>x hr.</Text>
                            </HStack>
                        </Badge>
                        <Text fontSize={14}>
                            {size.Capacity?.get(language)}
                        </Text>
                    </VStack>
                </CardBody>
            </Card>
            <Divider bgColor={'lightBrand'} my={3}/>
        </>
    )
}