import codes from "../assets/countries_phone_codes.json";

export interface JSONCountryCode {
    name: Name
    cca2: string
    idd: Idd
}

export interface Idd {
    root: string
    suffixes: string[]
}

export interface Name {
    common: string
    official: string
}

export interface CountryCode {
    CommonName: string
    OfficialName: string
    ShortName: string
    Code: string
}

export const jsonData = JSON.parse(JSON.stringify(codes)) as JSONCountryCode[];

const getCodes = () => {
    const aux = [] as CountryCode[]

    jsonData.sort((v1, v2) => (v1.name.common <= v2.name.common ? -1 : 1))
    for (const country of jsonData) {
        if (country.idd.suffixes.length === 0) {
            const code = {
                CommonName: country.name.common,
                OfficialName: country.name.official,
                ShortName: country.cca2,
                Code: country.idd.root.toString()
            }

            aux.push(code)
            continue
        }

        for (const cod of country.idd.suffixes) {
            const code = {
                CommonName: country.name.common,
                OfficialName: country.name.official,
                ShortName: country.cca2,
                Code: country.idd.root.toString() + cod
            }
            aux.push(code)
        }
    }

    return aux
}

export const CountryCodes = getCodes()