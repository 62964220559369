import {isAfter, isBefore, isEqual, set} from "date-fns";
import {Booking} from "../../interfaces/interfaces";
import {TimePicker} from "../common/TimePicker";


export const ExtendFromTimePicker = ({
                                         booking = {} as Booking,
                                         showNowItem = false,
                                         now = false,
                                         setNow = [] as any,
                                         minTime = set(new Date(), {seconds: 0, milliseconds: 0}),
                                         maxTime = set(new Date(), {seconds: 0, milliseconds: 0}),
                                         time = set(new Date(), {seconds: 0, milliseconds: 0}),
                                         setTime = [] as any
                                     }) => {
    let hrs = Array.from(Array(24).keys())

    hrs = hrs.filter((v) => {
        /* const hrMinTime = set(minTime, {hours: v, minutes: 0, milliseconds: 0})*/
        const hrTime = set(time, {hours: v, milliseconds: 0})
        const min = set(minTime, {milliseconds: 0})
        const max = set(maxTime, {milliseconds: 0})

        return (isEqual(hrTime, min) || isAfter(hrTime, min)) && (isEqual(hrTime, max) || isBefore(hrTime, max))
    })

    return (
        <TimePicker
                    booking={booking}
                    showNowItem={showNowItem}
                    isFromPicker={true}
                    isExtension={true}
                    time={time}
                    setTime={setTime}
                    now={now}
                    setNow={setNow}
                    hrs={hrs}
        />
    )
}