import {ExtendDetail} from "./ExtendDetail";
import {RefObject} from "react";
import {ExtendDateSelection} from "./ExtendDateSelection";
import {Booking} from "../../interfaces/interfaces";

export const ExtendSteps = ({
                                booking = {} as Booking,
                                cardRef = {} as RefObject<HTMLDivElement>,
                                registerHeadingRef = {} as RefObject<HTMLDivElement>,
                                height = ['auto'],
                                step = 0,
                                goToPrev = [] as any,
                                goToNext = [] as any
                            }): JSX.Element | null => {

    switch (step) {
        case 0:
            return <ExtendDateSelection booking={booking} cardRef={cardRef} registerRef={registerHeadingRef} height={height}
                                        goToNext={goToNext}/>
        case 1:
            return <ExtendDetail booking={booking} cardRef={cardRef} registerRef={registerHeadingRef} height={height}
                                 goToPrev={goToPrev}/>
    }

    return null;
}