import {useEffect, useState} from "react";
import {useCustomToast} from "./useCustomToast";
import {api} from "../api/api";
import axios from "axios";

export const useFetch = () => {
    const toast = useCustomToast({})
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    /* const [error, setError] = useState<Error | null>(null)*/

    useEffect(() => {

    }, []);
    const fetch = async (method: string, path: string, body: {}): Promise<any> => {
        setIsLoading(true)
        try {
            let res
            switch (method) {
                case "post":
                    res = await api.post(path, body)
                    break;
                case "put":
                    res = await api.put(path, body)
                    break;
                default:
                    res = await api.get(path)
            }

            setData(res.data);
        } catch (error: any) {
            setData({} as any)
            console.error("error when fetching data: ", error)
            if (axios.isAxiosError(error) || error instanceof Error) {
                return error // Handle generic JavaScript error
            } else {
                return new Error("An unknown error occurred") // Handle unknown error type
            }
        } finally {
            setIsLoading(false); // Set loading state to false after fetch completes
        }
    }

    return {
        fetch,
        data,
        isLoading
    }
}