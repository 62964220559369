import {isAfter, isEqual, set} from "date-fns";
import {TimePicker} from "../common/TimePicker";


export const FromTimePicker = ({
                                   showNowItem = false,
                                   minTime = set(new Date(), {seconds: 0, milliseconds: 0}),
                                   now = false,
                                   setNow = [] as any,
                                   time = set(new Date(), {seconds: 0, milliseconds: 0}),
                                   setTime = [] as any
                               }) => {
    let hrs = Array.from(Array(24).keys())

    hrs = hrs.filter((v) => {
        const hrTime = set(time, {hours: v, milliseconds: 0})
        const min = set(minTime, {milliseconds: 0})

        return isAfter(hrTime, min)
    })

    return (
        <TimePicker booking={null}
                    isFromPicker={true}
                    showNowItem={showNowItem}
                    time={time}
                    setTime={setTime}
                    now={now}
                    setNow={setNow}
                    hrs={hrs}
        />
    )
}