import {Box, Center, Fade, Heading, Image, VStack} from "@chakra-ui/react";
import logo from "../assets/img/lockers.webp";
import React, {useEffect, useRef, useState} from "react";
import {PaymentMethod} from "./PaymentMethod";
import {useTranslation} from "react-i18next";

const height = 750

export const PayBox = () => {
    const {t:tCommon} = useTranslation('common');
    const registerHeadingRef = useRef(null);
    const registerBoxRef = useRef(null);
    const cardsRef = useRef<HTMLDivElement>(null);
    const [registerHeight, setRegisterHeight] = useState(500);

    useEffect(() => {
        if (cardsRef && cardsRef.current) {
            setRegisterHeight(cardsRef.current.getBoundingClientRect().height);
        }

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setRegisterHeight(entry.contentRect.height); // Update parent's height based on the active child
            }
        });

        if (cardsRef.current) {
            resizeObserver.observe(cardsRef.current); // Observe changes in the active child
        }

        return () => {
            resizeObserver.disconnect(); // Cleanup observer on unmount
        };
    }, []);

    return (
        <Box ref={registerBoxRef} id={'payment'} position="relative" color={'lightBrand'}
             height={height}  w={'100%'} transition="height 0.5s ease">
            <Center w={'100%'}>
                <Image draggable={false} src={logo} w={'100%'}
                       height={height} objectFit={'cover'}/>
            </Center>
            <Box
                position="absolute"
                top={0}
                left={0}
                width={'100%'}
                height={'100%'}
                opacity={0.8} bgColor={'primary.500'}
            />

            <Fade in={true} transition={{enter: {duration: 0.5}}}>
                <VStack
                    position={'absolute'}
                    top={400}
                    left="50%"
                    transform="translate(-50%, -50%)"
                    textAlign="center"
                    w={['90%', '70%', '50%', '40%', '30%']}
                    justify={'center'}
                    m={'auto'}>
                    <Heading ref={registerHeadingRef} textAlign={'center'} fontSize={[30, 36, 44]} mb={4} textTransform='uppercase'>
                        {tCommon('PayTitle')}
                    </Heading>
                    <VStack w={'100%'} id={'pay_vstack'}>
                        <PaymentMethod cardRef={cardsRef} registerRef={registerBoxRef}/>
                    </VStack>
                </VStack>
            </Fade>
        </Box>
    );
};