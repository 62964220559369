import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Center,
    Collapse,
    Divider,
    Heading,
    HStack,
    Input,
    Stack,
    Text,
    VStack
} from "@chakra-ui/react";
import {addMinutes, addSeconds, format} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {PrevBtn} from "./PrevBtn";
import {motion} from "framer-motion";
import React, {useEffect, useState} from "react";
import {BookingDetail, Discount, SelectedSize, Size} from "../../interfaces/interfaces";
import {useTranslation} from "react-i18next";
import {validate} from "../../helpers/validation";


export const Detail = ({
                           booking = {} as BookingDetail,
                           title = '',
                           hrs = 0,
                           subTotal = 0,
                           total = 0,
                           discountAmount = 0,
                           showSizes = true,
                           discount = {} as Discount,
                           cardRef = {} as React.RefObject<HTMLDivElement>,
                           registerRef = {} as React.RefObject<HTMLDivElement>,
                           height = ['auto'],
                           showGoToPrev = true,
                           goToPrev = [] as any,
                           confirm = [] as any,
                           loadingConfirm = false,
                           remainingMinutes = 0
                       }) => {
    const {t, i18n: {language}} = useTranslation('detail');

    const [errors, setErrors] =
        useState<Map<string, string>>(new Map());

    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'})
    };

    useEffect(() => {
        scrollToSection(registerRef)
    }, []);

    useEffect(() => {
    }, [cardRef.current?.clientHeight]);

    const [confirmationEmail, setConfirmationEmail] = useState('');

    const handleConfEmailChange = ({target}: any) => {
        setConfirmationEmail(target.value);
    }

    const checkConfirmationEmail = (inputName: string, value: string) => {
        setErrors(validate(inputName, language, value, booking.Customer.Email));
    }

    const handleRemoveError = (name: string) => {
        const m = errors;
        m.delete(name)
        setErrors(new Map(m))
    }

    const isAllCompleted = () => {
        return booking.AcceptTermsAndConditions && confirmationEmail && booking.Location && booking.Branch &&
            booking.SelectedSizes.length > 0 && booking.From && booking.To
            && booking.Customer.Name && booking.Customer.LastName && booking.Customer.Email && booking.Customer.Phone
    }

    return (
        <Card ref={cardRef} bgColor={'secondary.500'} color={'lightBrand'}
              h={height}
              borderRadius={20} p={4} w={'100%'}>
            <CardHeader>
                <Heading fontSize={[22, 22, 24]}>{title}</Heading>
            </CardHeader>

            <CardBody p={2}>
                <VStack spacing='4'>
                    <VStack spacing={4} w={'100%'} textAlign={'left'} fontSize={['sm', 'md', 'lg']}>
                        <Box w={'100%'}>
                            <Heading fontSize={[14, 14, 16, 18]} w={'100%'} textAlign={'left'}
                                     textTransform='uppercase'>
                                {t('Branch')}
                            </Heading>
                            <HStack p={4} my={2} borderRadius={10} bgColor={'lightBrand'} color={'secondary.500'}
                                    justify={'center'}>
                                <Text>
                                    {booking.Branch.Address}
                                </Text>
                                <Center height={5}>
                                    <Divider orientation='vertical' borderColor={'secondary.500'} opacity={1}/>
                                </Center>
                                <Text>
                                    {booking.Location}
                                </Text>
                            </HStack>
                        </Box>
                        <Box w={'100%'}>
                            <Heading fontSize={[14, 14, 16, 18]} w={'100%'}
                                     textTransform='uppercase'>
                                {t('Date')}
                            </Heading>
                            <VStack p={4} my={2} borderRadius={10} bgColor={'lightBrand'} spacing={4} w={'100%'}
                                    align={'left'} color={'secondary.500'}>
                                <HStack justify={'space-between'}>
                                    <Text>
                                        {t('From')}:
                                    </Text>
                                    <Text>
                                        {format(booking.From, 'HH:mm dd-MM-yyyy')} (ARG)
                                    </Text>
                                </HStack>
                                <HStack justify={'space-between'}>
                                    <Text>
                                        {t('To')}:
                                    </Text>
                                    <Text>
                                        {format(booking.To, 'HH:mm dd-MM-yyyy')} (ARG)
                                    </Text>
                                </HStack>
                            </VStack>
                            {
                                remainingMinutes === 0 ?
                                    []
                                    :
                                    <HStack spacing={4} w={'100%'}>
                                        <FontAwesomeIcon fontSize={22} icon={faCircleInfo}/>
                                        <Text fontSize={[14, 16]}>
                                            {t('RemainingTimeExceeded', {
                                                remainingMinutes: remainingMinutes,
                                                time: format(addMinutes(booking.To, 1), "HH:mm")
                                            })}
                                        </Text>
                                    </HStack>
                            }
                        </Box>
                        <Box w={'100%'}>
                            <Heading fontSize={[14, 14, 16, 18]} w={'100%'} textAlign={'left'}
                                     textTransform='uppercase'>
                                {t('Customer')}
                            </Heading>
                            <VStack p={4} my={2} borderRadius={10} bgColor={'lightBrand'} spacing={4} w={'100%'}
                                    align={'left'} color={'secondary.500'}>
                                <Text>
                                    {booking.Customer.Name} {booking.Customer.LastName}
                                </Text>
                                <Text>
                                    {booking.Customer.Email}
                                </Text>
                                <Text>
                                    {booking.Customer.Phone}
                                </Text>
                            </VStack>
                        </Box>
                        {
                            showSizes ?
                                <Box w={'100%'}>
                                    <Heading fontSize={[14, 14, 16, 18]} w={'100%'} textAlign={'left'}
                                             textTransform='uppercase'>
                                        {t('Sizes')}
                                    </Heading>
                                    <VStack p={4} my={2} borderRadius={10} bgColor={'lightBrand'} spacing={2} w={'100%'}
                                            align={'left'} color={'secondary.500'}>
                                        {
                                            booking.SelectedSizes.map((size, idx) => (
                                                <SizeItem key={idx} hrs={hrs} selectedSize={size}
                                                          lastItem={booking.SelectedSizes.length === idx + 1}/>
                                            ))
                                        }
                                    </VStack>
                                </Box>
                                : []
                        }
                        <Box w={'100%'}>
                            <Heading fontSize={[14, 14, 16, 18]} w={'100%'} textAlign={'left'}
                                     textTransform='uppercase'>
                                {t('FinalPrice')}
                            </Heading>
                            <VStack p={4} my={2} borderRadius={10} bgColor={'lightBrand'} spacing={4} w={'100%'}
                                    align={'center'} color={'secondary.500'} fontSize={['sm', 'md', 'md', 'lg']}>

                                {
                                    discount.ID ?
                                        <>
                                            <HStack justify={'space-between'} w={'100%'}>
                                                <Text>
                                                    {t('Subtotal')}:
                                                </Text>
                                                <Text>
                                                    ARS {(subTotal).toFixed(2)}
                                                </Text>
                                            </HStack>
                                            <HStack justify={'space-between'} w={'100%'}>
                                                <HStack>
                                                    <Text>
                                                        {t('Discount')}
                                                        ({hrs === discount.MinHours ? '' : '+'}{discount.MinHours}hrs):
                                                    </Text>
                                                </HStack>
                                                <Text fontSize={['sm', 'md', 'md', 'lg']}>
                                                    ARS -{discountAmount.toFixed(2)}
                                                </Text>
                                            </HStack>

                                            <Center height={5} width={'100%'}>
                                                <Divider borderColor={'secondary.500'} opacity={1}/>
                                            </Center>
                                        </>
                                        : []
                                }
                                <Text fontSize={['lg', 'xl', '2xl']}>
                                    ARS&nbsp;
                                    {total.toFixed(2)}
                                </Text>

                            </VStack>
                        </Box>
                        <Divider/>
                        <Heading fontSize={[14, 14, 16, 18]} w={'100%'} textAlign={'left'}
                                 textTransform='uppercase'>
                            {t('ConfirmEmail')}
                        </Heading>
                        <Input textAlign={'left'} width={'100%'} placeholder={t('ConfirmEmail')}
                               value={confirmationEmail}
                               size={'md'}
                               onChange={handleConfEmailChange}
                               onBlur={() => checkConfirmationEmail('confirmationEmail', confirmationEmail)}
                               onFocus={() => handleRemoveError('confirmationEmail')}
                        />
                        <Stack w={'100%'}>
                            <Collapse in={errors.has('confirmationEmail')} animateOpacity>
                                <HStack spacing={2} w={'100%'} justify={'left'}>
                                    <FontAwesomeIcon color={'tomato'} icon={faCircleExclamation}/>
                                    <Text textAlign={'left'} color={'tomato'} fontSize={14} w={'100%'}>
                                        {errors.get('confirmationEmail')}
                                    </Text>
                                </HStack>
                            </Collapse>
                        </Stack>
                        <HStack spacing={4} w={'100%'}>
                            <FontAwesomeIcon fontSize={22} icon={faCircleInfo}/>
                            <Text fontSize={[14, 16]}>
                                {t('EmailMsg')}
                            </Text>
                        </HStack>
                    </VStack>
                    <Divider/>
                    <HStack w={'100%'} justifyContent={'center'} position={'relative'} bottom={0}>
                        {showGoToPrev ?
                            <PrevBtn prevStep={goToPrev}/>
                            : []
                        }
                        <Button w={'100%'} as={motion.button} whileHover={{scale: 1.05}}
                                _hover={{bgColor: 'primary.500'}}
                                bg={'primary.500'} color={'lightBrand'}
                                onClick={confirm}
                                isLoading={loadingConfirm}
                                isDisabled={!isAllCompleted() || errors.size > 0}>
                            {t('ConfirmBtn')}
                        </Button>
                    </HStack>
                </VStack>
            </CardBody>
        </Card>
    )
}


const SizeItem = ({hrs = 0, selectedSize = {} as SelectedSize, lastItem = false}) => {
    const {i18n: {language}} = useTranslation();
    return (
        <>
            {
                selectedSize.Size && selectedSize.SizeQty !== 0 ?
                    <>
                        <Box>
                            <Heading size='xs' textTransform='uppercase'>
                                {
                                    new Map(Object.entries(selectedSize.Size.Description)).size === 0 ?
                                        Object.fromEntries(selectedSize.Size.Description)[language]
                                        :
                                        new Map(Object.entries(selectedSize.Size.Description)).get(language)
                                }
                            </Heading>
                            <HStack pt='2' justify={'center'}>
                                <Text fontSize={['sm', 'md', 'lg']}>
                                    {selectedSize.SizeQty}
                                </Text>
                                <Text fontSize={['sm', 'md', 'lg']}>
                                    x
                                </Text>
                                <Text fontSize={['sm', 'md', 'lg']}>
                                    {hrs} {hrs === 1 ? 'hr.' : 'hrs.'}
                                </Text>
                                <Text fontSize={['sm', 'md', 'lg']}>
                                    =
                                </Text>
                                <Text fontSize={['sm', 'md', 'lg']}>
                                    ARS {Number(hrs * selectedSize.SizeQty * selectedSize.Size?.Price).toFixed(2)}
                                </Text>
                            </HStack>
                        </Box>
                        {
                            !lastItem ?
                                <Center height={5}>
                                    <Divider borderColor={'secondary.500'} opacity={1}/>
                                </Center>
                                : []
                        }
                    </>
                    : []
            }
        </>
    )
}