import {createSlice} from "@reduxjs/toolkit";

export const slice = createSlice({
    name: 'store',
    initialState: {
        booking: {
            Location: localStorage.getItem("location"),
            Branch: localStorage.getItem("branch"),
            SelectedSizes: localStorage.getItem("selectedSizes")
        },
        token: localStorage.getItem("token"),
        webName: "U LOCKER",
    },
    reducers: {
        booking: (state, action) => {
            if (!action.payload) {
                return
            }

            state.booking.SelectedSizes = action.payload.SelectedSizes
            state.booking.Location = action.payload.Location
            state.booking.SelectedSizes = action.payload.SelectedSizes

            localStorage.setItem("location", action.payload.Location)
            localStorage.setItem("branch", action.payload.Branch)
            localStorage.setItem("selectedSizes", action.payload.SelectedSizes)
        },
        login: (state, action) => {
            if (action.payload) {
                state.token = action.payload.token

                localStorage.setItem("token", action.payload.token)
            }
        },
        logout: (state) => {
            state.token = null
            localStorage.clear()
        }
    }
})


export const {booking, login, logout} = slice.actions