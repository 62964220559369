import {
    Button,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";


export const TermsAndConditions = ({
                                       isOpen = false,
                                       onClose = [] as any,
                                       accept = [] as any
                                   }) => {
    const [scrolled, setScrolled] = useState<boolean>(false);

    const handleModalScroll = ({target}: any) => {
        setScrolled(((target.scrollHeight - target.scrollTop) - 25) <= target.clientHeight)
    }

    const {t, i18n: {language}} = useTranslation('customer');
    const [tyc, setTYC] = useState('')

    const getTYCFile = async () => {
        try {
            const response = await fetch(`/tyc/${language}.txt`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const text = await response.text();
            setTYC(text);
        } catch (error) {
            console.error('Error fetching the file:', error);
        }
    }

    useEffect(() => {
        getTYCFile()
    }, [language]);



    return (
        <Modal closeOnOverlayClick={false} scrollBehavior={'inside'} isOpen={isOpen} onClose={onClose}
               size={['sm', 'md', 'lg', 'xl']}>
            <ModalOverlay/>
            <ModalContent color={'secondary.500'} borderRadius={20}>
                <ModalHeader>
                    <Heading size={'lg'}>
                        {t('TermsAndConditions')}
                    </Heading>
                    <HStack spacing={2} justify={'left'} fontSize={14} mt={4}>
                        <FontAwesomeIcon fontSize={22} icon={faCircleInfo}/>
                        <Text>
                            {t('TermsAndConditionsMsg')}
                        </Text>
                    </HStack>
                </ModalHeader>
                <ModalBody fontWeight={'normal'} py={5} textAlign={'justify'} onScroll={handleModalScroll}
                           borderWidth={1} borderRadius={10} overflowY={'auto'} mx={5} borderColor={'gray'}>
                        {tyc}
                </ModalBody>
                <ModalFooter>
                <Button isDisabled={!scrolled} bgColor={'secondary.500'} _hover={{bgColor: 'primary.500'}}
                            colorScheme='blue'
                            mr={3} onClick={accept}>
                    {t('Accept')}
                    </Button>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}