import {useToast} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {addMinutes, format, parseISO} from "date-fns";
import {useNavigate} from "react-router-dom";
import {Booking, PayExtraTime, SelectedSize, Size, StorageSize} from "../../interfaces/interfaces";
import {useFetch} from "../../hooks/useFetch";
import {getSelectedSizeFromStorage, mapToJson} from "../../helpers/common";
import {Detail} from "../common/Detail";
import {useTranslation} from "react-i18next";

const sizesURL = '/size/all'
const payPenaltyURL = '/pay_penalty'

export const PayPenaltyDetail = ({
                                     bookingToPay = {} as Booking,
                                     cardRef = {} as React.RefObject<HTMLDivElement>,
                                     registerRef = {} as React.RefObject<HTMLDivElement>,
                                     height = ['fit-content', 'auto'],
                                 }) => {
    const {t: tCommon} = useTranslation('common');
    const {t, i18n: {language}} = useTranslation('detail');
    const toast = useToast()
    const navigate = useNavigate()

    const [from] = useState(bookingToPay.From);
    const [to] = useState(new Date());
    const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);

    const [selectedStorageSizes] =
        useState(localStorage.getItem('selectedSizes') ?
            JSON.parse(localStorage.getItem('selectedSizes')!) as StorageSize[] : [] as StorageSize[])

    const [selectedSizes, setSelectedSizes] = useState([] as SelectedSize[])
    const [total, setTotal] = useState(0)

    const {data: sizes = [] as Size[], fetch: fetchSizes, isLoading: loadingSizes} = useFetch()
    const {fetch: fetchPayPenalty, isLoading: loadingPayPenalty} = useFetch()

    // Scroll to the section when button is clicked
    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'})
    };

    const getSizes = async () => {
        await fetchSizes("get", sizesURL, {})
    }

    useEffect(() => {
        scrollToSection(registerRef)
        getSizes()
    }, []);

    useEffect(() => {
        setSelectedSizes(getSelectedSizeFromStorage(selectedStorageSizes))
    }, []);

    useEffect(() => {
        let size = {} as Size
        sizes.forEach((s = {} as Size) => {
            if (s.Type === 'Large') {
                size = s
            }
        })

        if (process.env.REACT_APP_PENALTY_HRS_ADDITION === undefined ||
            isNaN(Number(process.env.REACT_APP_PENALTY_HRS_ADDITION))) {
            toast({
                title: t('Errors.PenaltyNotCalculated'),
                description: tCommon('Errors.PleaseContactUs'),
                status: 'error',
                duration: 10000,
                isClosable: false,
            })

            navigate(`/`, {replace: true})
        }

        setTotal(size.Price * Number(process.env.REACT_APP_PENALTY_HRS_ADDITION))
    }, [sizes]);

    useEffect(() => {
        if (bookingToPay !== undefined && bookingToPay.ID) {
            const auxList = [] as any
            Object.entries((bookingToPay as Booking).Spaces).forEach(([k, v]) => {
                let descMap = new Map<string, any>()

                Object.entries(v.Size.Description).forEach(([k, v]) => {
                    descMap.set(k, v)
                })

                let capMap = new Map<string, any>()
                Object.entries(v.Size.Capacity).forEach(([k, v]) => {
                    capMap.set(k, v)
                })

                const desc = mapToJson(descMap)
                const capacity = mapToJson(capMap)
                const newAux: {
                    ID: string
                    Description: string
                    Type: string
                    Width: number
                    Height: number
                    Depth: number
                    Price: number
                    ViewOrder: number
                    Capacity: string
                    CreatedAt: string
                    UpdatedAt: string
                    SizeQty: number
                } = {...v.Size, Description: desc, Capacity: capacity, SizeQty: 1}

                let add = true
                for (let i = 0; i < auxList.length; i++) {
                    if (auxList[i].ID === newAux.ID) {
                        auxList[i].SizeQty++
                        add = false
                        break
                    }

                }

                if (add) {
                    auxList.push(newAux)
                }
            })

            let acceptTermsAndConditions = ''
            Object.entries((bookingToPay as Booking).AdditionalInfo).forEach(([k, v]) => {
                if (k === 'acceptTermsAndConditions') {
                    acceptTermsAndConditions = v
                }
            })

            setAcceptTermsAndConditions(acceptTermsAndConditions === 'true')
            setSelectedSizes(auxList)
        }

    }, [bookingToPay]);

    const confirm = async () => {
        const body: PayExtraTime = {
            From: from,
            To: to.toISOString(),
            BookingID: (bookingToPay as Booking)?.ID,
            TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        const error = await fetchPayPenalty('post', payPenaltyURL, body)

        if (error) {
            toast({
                title: t('Errors.OrderNotCreated'),
                description: tCommon('Errors.PleaseContactUs'),
                status: 'error',
                duration: 10000,
                isClosable: false,
            })

            return
        }

        toast({
            title: t('Success.OrderCreated'),
            description: tCommon('Success.CheckInbox'),
            status: 'success',
            duration: 10000,
            isClosable: false,
        })

        navigate('/', {replace: true})
    }

    return (

        <Detail
            booking={{
                Location: bookingToPay.Branch.City,
                Branch: bookingToPay.Branch,
                From: from.toString(),
                To: to.toISOString(),
                SelectedSizes: selectedSizes,
                Customer: {
                    Name: bookingToPay.Customer.Name,
                    LastName: bookingToPay.Customer.LastName,
                    Email: bookingToPay.Customer.Email,
                    Phone: bookingToPay.Customer.Phone,
                    DiscoveryMethod: bookingToPay.Customer.DiscoveryMethod,
                    Language: language,
                },
                AcceptTermsAndConditions: acceptTermsAndConditions,
            }}
            title={t('PenaltyTitle')}
            cardRef={cardRef}
            registerRef={registerRef}
            total={total}
            showSizes={false}
            confirm={confirm}
            height={height}
            showGoToPrev={false}
            loadingConfirm={loadingPayPenalty}/>
    )
}