import {Button, Divider, HStack, Link, LinkBox, LinkOverlay, Stack, StackProps, Text, VStack} from '@chakra-ui/react';
import {FooterLogo} from "./FooterLogo";
import {faInstagram, faTiktok} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";


export const Footer = () => {
    const style: StackProps = {
        position: 'relative', bottom: '0', color: 'lightBrand',
        bg: 'secondary.500', h: ['700', '700', '700', '350'], width: '100%', opacity: 1, py: 6, px: 10
    } //h: ['900', '900', '900', '550']
    const {t, i18n: {language}} = useTranslation('footer');

    const tyc = `/tyc/${language}.pdf`

    const handleTYCClick = () => {
        window.open(tyc, '_blank');
    }

    return (
        <Stack {...style} direction={['column', 'column', 'column', 'row']}>
            <VStack width={'100%'} justify={'center'} height={'100%'}>
                <VStack width={['100%', '80%', '80%', '80%']} spacing={2}
                        align={['center', 'center', 'center', 'left']}>
                    <LinkBox w={'100%'} textAlign={'center'}
                             as={motion.button}
                             whileHover={{scale: 1.1}}>
                        <HStack spacing={2} mb={2} justify={'center'}>
                            <FontAwesomeIcon icon={faLocationDot}/>
                            <Text fontWeight={'bold'}>
                                {t('WeAreIn')}
                            </Text>
                        </HStack>
                        <Text fontSize={12}>
                            <LinkOverlay href={'https://maps.app.goo.gl/KTw7dr6pMFgPJ5V46'} target={'_blank'}>
                                Pres. Bernardino Rivadavia 132, Ushuaia, Tierra del Fuego
                            </LinkOverlay>
                        </Text>
                    </LinkBox>
                    <Divider my={2}/>
                    <LinkBox w={'100%'} textAlign={'center'}
                             as={motion.button}
                             whileHover={{scale: 1.1}}>
                        <HStack spacing={2} justify={'center'}>
                            <FontAwesomeIcon icon={faEnvelope}/>
                            <LinkOverlay
                                href={'mailto:contacto@ulocker.ar?subject=Quiero%20más%20información%20sobre%20el%20servicio'}
                                target={'_blank'}>
                                contacto@ulocker.ar
                            </LinkOverlay>
                        </HStack>
                    </LinkBox>
                    <Divider my={2}/>
                    <Button variant={'ghost'}
                            as={motion.button}
                            whileHover={{scale: 1.1}}
                            _hover={{bgColor: 'secondary.500'}}
                            fontSize={[12]}
                            color={'lightBrand'}
                            onClick={handleTYCClick}>
                        {t('TermsAndConditions')}
                    </Button>
                </VStack>
            </VStack>
            <Stack width={'100%'} spacing={2} justify={'center'} align={'center'}>
                <FooterLogo width={'250px'}/>
            </Stack>
            <HStack width={'100%'} py={[10, 10, 10, 0]} spacing={2} align={'center'} justify={'space-evenly'}>
                <Link href={'https://www.instagram.com/ulockertdf'}
                      isExternal={true}
                      target={'_blank'}
                      aria-label={'instagram'}
                      as={motion.a} whileHover={{scale: 1.1}}>
                    <FontAwesomeIcon fontSize={40} icon={faInstagram}/>
                </Link>
                <Link href={'https://www.tiktok.com/@ulockertdf'}
                      target={'_blank'}
                      aria-label={'tiktok'}
                      as={motion.a} whileHover={{scale: 1.1}}>
                    <FontAwesomeIcon fontSize={30} icon={faTiktok}/>
                </Link>

            </HStack>
        </Stack>
    );
};