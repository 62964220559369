import {HStack, IconButton, Link, Stack} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Logo} from "./Logo";
import {LanguageMenuBtn} from "./LanguageMenuBtn";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

export const Header = () => {
    const {t} = useTranslation('header');
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen) {
            // Lock scroll
            document.body.style.overflow = 'hidden';
        } else {
            // Unlock scroll
            document.body.style.overflow = 'unset';
        }
        // Cleanup function to reset scroll when component unmounts or state changes
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);


    const scrollIntoViewWithOffset = (selector: any, offset: number = 0) => {
        window.scrollTo({
            behavior: 'smooth',
            top:
                document.querySelector(selector).getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                offset,
        })
    }

    const handleFAQClick = () => {
        setIsOpen(false)
        if (location.pathname !== '/') {
            navigate("/")
            setTimeout(() => {
                scrollIntoViewWithOffset('#faq', 104)
            }, 100)

            return;
        }
        scrollIntoViewWithOffset('#faq', 104)
    }

    const handleHowToClick = () => {
        setIsOpen(false)
        if (location.pathname !== '/') {
            navigate("/")
            setTimeout(() => {
                scrollIntoViewWithOffset('#howTo', 50)
            }, 100)

            return;
        }

        scrollIntoViewWithOffset('#howTo', 50)
    }

    return (
        <HStack
            position="fixed" top={0} zIndex={'docked'} color={'lightBrand'} bg={'secondary.500'} px={0} py={0}
            width={'100%'}
            height={[isOpen ? '100vh' : '104', isOpen ? '100vh' : '104', '104']}
            transition="height 0.5s ease"
            alignItems={'start'}
            overflowY={isOpen ? 'hidden' : 'visible'}>
            <Stack direction={[isOpen ? 'column' : 'row', isOpen ? 'column' : 'row', 'row', 'row']} w={'100%'}
                   justify={'space-around'}>
                <HStack w={['100%','100%','auto']} justify={'space-between'}>
                    <Link href={'/'}>
                        <Logo width={'200px'}/>
                    </Link>
                    <IconButton fontSize={26} color={'lightBrand'}
                                icon={isOpen ? <FontAwesomeIcon icon={faXmark}/> : <FontAwesomeIcon icon={faBars}/>}
                                aria-label="Open Menu" display={{md: 'none'}} onClick={toggleMenu}
                                bgColor={'secondary.500'}
                                _hover={{bgColor: 'secondary.500'}} me={10}
                    />
                </HStack>
                <Stack direction={[isOpen ? 'column' : 'row', isOpen ? 'column' : 'row', 'row', 'row']}
                       display={[isOpen ? 'flex' : 'none', isOpen ? 'flex' : 'none', 'flex', 'flex']}
                       align={'center'}
                       as="nav" spacing={10} me={[isOpen ? 0 : 10, isOpen ? 0 : 10, 10, 10]}
                       mt={[isOpen ? 10 : 0, isOpen ? 10 : 0, 0, 0]}>
                    <Link color="white" width={'max-content'} textDecoration={'none'}
                          _hover={{color: 'primary.500'}} onClick={handleHowToClick}>
                        {t('HowItWorks')}
                    </Link>
                    <Link color="white" width={'max-content'} textDecoration={'none'}
                          _hover={{color: 'primary.500'}} onClick={handleFAQClick}>
                        {t('FAQs')}
                    </Link>
                    <LanguageMenuBtn closeMenu={() => setIsOpen(false)} />
                </Stack>
            </Stack>
        </HStack>
    );
};