import {Box, Center, Fade, Heading, HStack, Image, Text, VStack} from "@chakra-ui/react";
import img from "../../assets/img/benefits.webp";
import React from "react";
import shield from "../../assets/icons/shield.svg";
import open24_7 from "../../assets/icons/24-7.svg";
import volt from "../../assets/icons/volt.svg";
import calendar from "../../assets/icons/calendar.svg";
import {useTranslation} from "react-i18next";

const height = [1100, 800, 800, '100vh']

export const Benefits = () => {
    const {t} = useTranslation('landing');
    return (
        <Box id={'howTo'} position="relative" w={'100%'} height={height}>
            <Center w={'100%'}>
                <Image draggable={false} src={img} w={'100%'}
                       height={height} objectFit="cover"/>
            </Center>
            <Box
                position="absolute"
                top={0}
                left={0}
                width={'100%'}
                height={'100%'}
                opacity={0.8} bgColor={'primary.500'}
            />
            <Fade in={true} transition={{enter: {duration: 0.5}}}>
                <VStack position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        color={'lightBrand'}
                        w={'100%'}
                        m={'auto'}
                        px={'10%'}
                        justify={'center'}
                        height={'auto'}
                >

                    <Heading color={'secondary.500'}>U LOCK IT</Heading>
                    <Heading textAlign={'center'} fontSize={[24, 24, 44]}>
                        {t('SelfService')}
                    </Heading>
                    <VStack mt={50} align={'start'} spacing={6} color={'lightBrand'}>
                        <Benefit image={shield} title={t('SecurityTitle')}
                                 text={t('SecurityText')}/>
                        <Benefit image={open24_7} title={t('AllDayOpenTitle')}
                                 text={t('AllDayOpenText')}/>
                        <Benefit image={volt} title={t('BookSafeAndQuicklyTitle')}
                                 text={t('BookSafeAndQuicklyText')}/>
                        <Benefit image={calendar} title={t('ScheduledBookingTitle')}
                                 text={t('ScheduledBookingText')}/>
                    </VStack>
                </VStack>
            </Fade>
        </Box>
    );
};

const Benefit = ({image = [] as any, title = "", text = ""}) => {
    return (
        <HStack spacing={5}>
            <Image draggable={false} p={2} borderRadius={10} bgColor={'secondary.500'} boxSize={58} src={image}/>
            <VStack spacing={1} align={'left'} w={'100%'}>
                <Text fontSize={20} fontWeight={'bold'}>{title}</Text>
                <Text color={'secondary.500'}>{text}</Text>
            </VStack>
        </HStack>
    )
}