import {Button} from "@chakra-ui/react";
import {motion} from "framer-motion";
import React from "react";
import {useTranslation} from "react-i18next";

export const NextBtn = ({isDisabled = false, nextStep = [] as any}) => {
    const {t:tCommon} = useTranslation('common');
    return (
        <Button w={'100%'} as={motion.button} whileHover={{scale: 1.02}} _hover={{bgColor: 'primary.500'}}
                bg={'primary.500'} color={'lightBrand'}
                isDisabled={isDisabled}
                onClick={nextStep}>
            {tCommon('NextBtn')}
        </Button>
    )
}