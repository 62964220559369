import {Image} from "@chakra-ui/react";
import logo from "../../assets/img/logo.webp";

export const Logo = ({width = '300px'}) => {
    return (
        <>
            <Image draggable={false} src={logo} alt='favicon' width={width}/>
        </>
    );
}
