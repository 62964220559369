import {Button, Text, VStack} from "@chakra-ui/react";
import {motion} from "framer-motion";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const BookingInvalidStateAlert = ({errors = {} as Map<string, string>, height = ['auto']}) => {
    const {t: tCommon} = useTranslation('common');
    const navigate = useNavigate()
    const [navigating, setNavigating] = useState(false);

    const handleNavigate = (path: string) => {
        setNavigating(true)
        navigate(path, {replace: true})
    }

    return (
        <VStack spacing={10} justify={'center'} mt={2} bgColor={'secondary.500'} p={8} borderRadius={20}>
            <Text fontSize={[22, 22, 24]} mt={2}>
                {errors.values().next().value}
            </Text>
            <Button w={'100%'} as={motion.button} whileHover={{scale: 1.05}}
                    _hover={{bgColor: 'primary.500'}}
                    bg={'primary.500'} color={'lightBrand'}
                    onClick={() => handleNavigate('/')}
                    isLoading={navigating}>
                {tCommon('BackHomePage')}
            </Button>
        </VStack>
    )
}