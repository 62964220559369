import {Image} from "@chakra-ui/react";
import Medium from "../../assets/img/spaces/medium_locker_size.webp";
import Large from "../../assets/img/spaces/large_locker_size.webp";
import Vault from "../../assets/img/spaces/vault.webp";

const images = {
    Medium,
    Large,
    Vault
} as any

export const SizeImage = ({width = '300px', type = '', isLoading = [] as any}) => {
    const src = images[type];
    return (
        <>
            <Image draggable={false} src={src} alt='medium_locker_size' width={width}/>
        </>
    );
}
