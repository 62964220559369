import {Header} from "../components/common/Header";
import {Footer} from "../components/common/Footer";
import {Enjoy} from "../components/main/Enjoy";
import {BranchesAndSizes} from "../components/main/BranchesAndSizes";
import {Benefits} from "../components/main/Benefits";
import {FAQ} from "../components/main/FAQ";
import {Rent} from "../components/main/Rent";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export const Main = () => {
    const location = useLocation()
    const {i18n: {changeLanguage}} = useTranslation();
    localStorage.clear()
    localStorage.setItem("version", `${process.env.REACT_APP_VERSION}`)


    useEffect(() => {
        if (location.search.includes("?lang")) {
            const split = location.search.split("?lang=")
            if (split.length > 1) {
                changeLanguage(split[1])
            }
        }
    }, [])

    return (
        <>
            <Header/>
            <Enjoy/>
            <BranchesAndSizes/>
            <Benefits/>
            <FAQ/>
            <Rent/>
            <Footer/>
        </>
    )
}