import {Image} from "@chakra-ui/react";
import logo from "../../assets/img/secondary_logo.webp";

export const FooterLogo = ({width = '350px'}) => {
    return (
        <>
            <Image draggable={false} src={logo} alt='favicon' width={width}/>
        </>
    );
}
