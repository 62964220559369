import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CircularProgress,
    Collapse,
    Divider,
    Heading,
    HStack,
    Image,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import mp_logo from "../assets/img/payments/mercado_pago.svg";
import paypal_logo from "../assets/img/payments/paypal.svg";
import visa from "../assets/img/payments/visa.svg";
import mastercard from "../assets/img/payments/mastercard.svg";
import amex from "../assets/img/payments/amex.svg";
import creditCard from "../assets/img/payments/creditcard_plus.svg";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFetch} from "../hooks/useFetch";
import {motion} from "framer-motion";
import {useParams} from "react-router-dom";
import {
    CreditCard,
    HTTPErrorCodeBookingInvalidForPayment,
    HTTPErrorCodeBookingInvalidOrExpired,
    MercadoPago,
    PayPal
} from "../common/constants";
import {BookingInvalidStateAlert} from "./common/BookingInvalidStateAlert";
import {BookingFinalizedAlert} from "./common/BookingFinalizedAlert";
import {validateState} from "../helpers/states_validation";
import {useTranslation} from "react-i18next";
import {useClientInfo} from "../hooks/useClientInfo";

const checkValidForPaymentURL = "/payment/creation/check"
const createPaymentURL = "/payment/create"
const sendError = "/payment/error"

export const PaymentMethod = ({
                                  cardRef = {} as React.RefObject<HTMLDivElement>,
                                  registerRef = {} as React.RefObject<HTMLDivElement>,
                                  height = ['auto']
                              }) => {
    const {t, i18n: {language}} = useTranslation('payment');
    const {t: tCommon} = useTranslation('common');
    const toast = useToast()
    const [payment, setPayment] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [errors, setErrors] =
        useState<Map<string, string>>(new Map());
    const clientInfo = useClientInfo();

    const {bookingID} = useParams();

    const {
        fetch: fetchValidateBooking,
        isLoading: isValidatingBooking
    } = useFetch()

    const {
        data: paymentLink,
        fetch: fetchCreatePayment,
        isLoading: isLoadingPayment
    } = useFetch()

    const {
        fetch: fetchSendError,
    } = useFetch()

    const handleSetPayment = (value: string) => {
        setPayment(value)
    }

    // Scroll to the section when button is clicked
    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        scrollToSection(registerRef)
        validateBooking()
    }, []);

    const validateBooking = async () => {
        const error = await fetchValidateBooking("get", checkValidForPaymentURL + "/" + bookingID, {})

        setErrors(validateState(error?.response?.data?.Code, language))
    }

    const handleConfirmPayment = async () => {
        const error = await fetchCreatePayment("post", createPaymentURL, {
            BookingID: bookingID,
            PaymentMethod: payment,
        })


        if (error?.response?.data?.Code === HTTPErrorCodeBookingInvalidForPayment ||
            error?.response?.data?.Code === HTTPErrorCodeBookingInvalidOrExpired) {

            toast({
                title: t('Errors.InvalidOrExpiredBooking'),
                description: tCommon('Errors.IfNecessaryPleaseContactUs'),
                status: 'error',
                duration: 5000,
                isClosable: false,
            })

            return
        }

        if (error instanceof Error) {
            toast({
                title: t('Errors.PaymentCreation'),
                description: tCommon('Errors.PleaseContactUs'),
                status: 'error',
                duration: 5000,
                isClosable: false,
            })

            return
        }

        setIsConfirmed(true)
    }

    useEffect(() => {
        if (isConfirmed && paymentLink) {
            try {
                window.open(paymentLink.toString(), '_self');

                setTimeout(() => {
                    if (!document.hidden) { // If the page remains visible
                        toast({
                            title: tCommon('Errors.PaymentError'),
                            description: tCommon('Errors.IfNecessaryPleaseContactUs'),
                            status: 'error',
                            duration: 10000,
                            isClosable: false,
                        });
                        fetchSendError("post", sendError, {
                            "Error": `redirección fallida después de intentar abrir el enlace: '${paymentLink}'`,
                            "Time": new Date().toISOString(),
                            "ClientInfo": clientInfo
                        });
                    }
                }, 5000);

            } catch (error: any) {
                toast({
                    title: tCommon('Errors.PaymentError'),
                    description: tCommon('Errors.IfNecessaryPleaseContactUs'),
                    status: 'error',
                    duration: 10000,
                    isClosable: false,
                })

                fetchSendError("post", sendError, {
                    "Error": `redirección fallida después de intentar abrir el enlace: '${paymentLink}': error: ${error}`,
                    "Time": new Date().toISOString(),
                    "ClientInfo": clientInfo
                })
            }
        }
    }, [paymentLink]);


    return (
        <Card ref={cardRef} bgColor={'secondary.500'} color={'lightBrand'}
              h={height}
              borderRadius={20} p={4} w={'100%'}>
            {
                errors.size === 0 ?
                    <CardHeader>
                        <Heading fontSize={[22, 22, 24]}>
                            {t('ChoosePayment')}
                        </Heading>
                    </CardHeader>
                    : []
            }

            <CardBody p={2}>
                {isValidatingBooking ?
                    <CircularProgress isIndeterminate color={'primary.500'}/>
                    :
                    errors.size !== 0 && !errors.has(HTTPErrorCodeBookingInvalidForPayment) ?
                        <BookingInvalidStateAlert errors={errors}/>
                        :
                        errors.has(HTTPErrorCodeBookingInvalidForPayment) ?
                            <BookingFinalizedAlert errors={errors}/>
                            :
                            <VStack spacing='3' justify={'left'} width='100%'>
                               {/* <Method
                                    logos={[visa, mastercard, creditCard]}
                                    name={CreditCard}
                                    selected={payment}
                                    setPayment={handleSetPayment}
                                />
                                <Divider/>*/}
                                <Method
                                    logos={[mp_logo]}
                                    name={MercadoPago}
                                    selected={payment}
                                    setPayment={handleSetPayment}
                                />
                                <Divider/>
                                <Method
                                    logos={[paypal_logo]}
                                    name={PayPal}
                                    selected={payment}
                                    setPayment={handleSetPayment}
                                />
                                <Divider/>
                                {/* <Method
                        logo={mp_logo}
                        name={'Pix'}
                        selected={payment}
                        setPayment={handleSetPayment}
                    />
                    <Divider/>*/}

                                <Collapse in={payment === CreditCard} animateOpacity>
                                    <HStack spacing={2} w={'100%'} justify={'center'}>
                                        <FontAwesomeIcon fontSize={22} icon={faCircleInfo}/>
                                        <Text fontSize={[14, 16]}>
                                            {t('CreditCardDoc')}
                                        </Text>
                                    </HStack>
                                </Collapse>

                                <Collapse in={payment === MercadoPago} animateOpacity>
                                    <HStack spacing={2} w={'100%'} justify={'center'}>
                                        <FontAwesomeIcon fontSize={22} icon={faCircleInfo}/>
                                        <Text fontSize={[14, 16]}>
                                            {t('OnlyARS')}
                                        </Text>
                                    </HStack>
                                </Collapse>
                                <Collapse in={payment === PayPal} animateOpacity>
                                    <HStack spacing={2} w={'100%'} justify={'center'}>
                                        <FontAwesomeIcon fontSize={22} icon={faCircleInfo}/>
                                        <Text fontSize={[14, 16]}>
                                            {t('OnlyForeignCards')}
                                        </Text>
                                    </HStack>
                                </Collapse>
                                <HStack w={'100%'} justifyContent={'center'} position={'relative'} bottom={0}>
                                    <Button w={'100%'} as={motion.button} whileHover={{scale: 1.05}}
                                            _hover={{bgColor: 'primary.500'}}
                                            bg={'primary.500'} color={'lightBrand'}
                                            onClick={handleConfirmPayment}
                                            isLoading={isLoadingPayment || isConfirmed}
                                            isDisabled={payment === ''}>
                                        {t('Pay')}
                                    </Button>
                                </HStack>
                            </VStack>
                }
            </CardBody>
        </Card>
    )
}

const Method = ({logos = [""], name = '', selected = '', setPayment = [] as any}) => {
    const selectedColor = "lightsteelblue"

    return (
        <Button color={'lightBrand'}
                bgColor={name === selected ? selectedColor : 'lightBrand'}
                py={8}
                onClick={() => setPayment(name)}
                variant='outline'
                width={['80%', '80%', '70%', '60%']}
                _hover={{bgColor: name === selected ? selectedColor : 'lightBrand'}}
                borderColor={"transparent"}>
            <HStack justify={'center'}>
                {
                    name === CreditCard ?
                        <HStack w={'100%'} justify={'space-evenly'}>
                            {
                                logos.map((logo) => (
                                    <Image draggable={false} src={logo} alt={name + "_" + logo} width={'25%'}/>
                                ))
                            }
                        </HStack>
                        :
                        <Image draggable={false} src={logos[0]} alt={name} width={'60%'}/>
                }
            </HStack>
        </Button>
    )
}