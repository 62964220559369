import React, {useEffect, useMemo, useRef, useState} from "react";
import {CountryCode, CountryCodes} from "../../interfaces/phone_code";
import {Box, HStack, Input, Menu, MenuButton, MenuItem, MenuList, Text} from "@chakra-ui/react";
import {useDebounce} from "../../hooks/useDebounce";
import {useTranslation} from "react-i18next";

export const PhoneCodeSelector = ({countryCode = {} as CountryCode, setCountryCode = [] as any}) => {
    const {t} = useTranslation('customer');
    const searchRef = useRef<HTMLInputElement>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [codes, setCodes] = useState(CountryCodes);
    const { debouncedValue, setDebouncedValue } = useDebounce(200);

    const filtered = useMemo(() => {
        return CountryCodes.filter(country =>
            country.CommonName.toLowerCase().includes(debouncedValue) ||
            country.OfficialName.toLowerCase().includes(debouncedValue) ||
            country.Code.includes(debouncedValue)
        );
    }, [debouncedValue]);

    useEffect(() => {
        if (debouncedValue) {
            setCodes(filtered);
        }
    }, [debouncedValue, filtered]);

    const handleSearchChange = ({target}: any) => {
        setSearchTerm(target.value.toLowerCase());
    };

    const handleStopTyping = ({target}: any) => {
        const value = target.value.toLowerCase();

        if (Number.isNaN(target.value) && target.value.length < 2) {
            return
        }

        setDebouncedValue(value)
    }

    const handleCountryChange = (value = {} as CountryCode) => {
        setCountryCode(value)
    }

    useEffect(() => {
        if (searchTerm === '') {
            setCodes([]);
        }

        setTimeout(() => {
            searchRef.current!.focus()
        }, 100)
    }, [searchTerm]);

    useEffect(() => {
        if (Object.keys(countryCode).length === 0) {
            setCountryCode(CountryCodes.filter(countryCode => countryCode.CommonName.toLowerCase() === 'argentina').at(0)!);
        }
    }, []);

    // Memoize the rendered list of menu items to avoid re-renders
    const renderedCountryList = useMemo(() => (
        (codes as CountryCode[]).map((aux, index) => (
            <MenuItem
                color={'lightBrand'}
                justifyContent={'center'}
                py={2}
                bgColor={aux.Code === countryCode.Code ? 'primary.500' : 'secondary.500'}
                onClick={() => handleCountryChange(aux)}
                _hover={{ bgColor: 'primary.500' }}
                key={index}
            >
                <HStack justify={'space-between'} w={'100%'}>
                    <Text>{aux.CommonName}</Text>
                    <Text>{aux.Code}</Text>
                </HStack>
            </MenuItem>
        ))
    ), [codes, countryCode]);

    return (
        <Menu matchWidth={true} initialFocusRef={searchRef}>
            <MenuButton mt={2}
                        bgColor={'secondary.500'}
                        borderColor={'lightBrand'}
                        outline={'none'}
                        py={'0.44rem'}
                        w={'40%'}
                        transition='all 0.2s'
                        borderRadius='md'
                        borderWidth='1px'
                        _hover={{borderColor: 'lightBrand'}}
                        _focus={{borderColor: 'lightBrand'}}>
                {countryCode.ShortName} {countryCode.Code}
            </MenuButton>
            <MenuList pt={0}
                      opacity={0.8}
                      bgColor={'secondary.500'}
                      borderColor={'lightBrand'}
                      overflowY={'scroll'}
                      maxHeight={260}>
                <Box borderTopRadius={'md'} p={2} position="sticky" top="0" bgColor={'secondary.500'}>
                    <Input
                        ref={searchRef}
                        onKeyUp={handleStopTyping}
                        placeholder={t('SearchCode')}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Box>
                {renderedCountryList}
            </MenuList>
        </Menu>
    );
};