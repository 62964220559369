import {
    Card,
    CardBody,
    CardHeader,
    Collapse,
    Divider,
    Heading,
    HStack,
    ListItem,
    Stack,
    Text,
    UnorderedList,
    useToast,
    VStack
} from "@chakra-ui/react";
import {NextBtn} from "../common/NextBtn";
import React, {useEffect, useState} from "react";
import {
    addDays,
    addHours,
    addMinutes,
    addMonths,
    differenceInMinutes,
    format,
    isAfter,
    isBefore,
    isEqual,
    isSameDay,
    isToday,
    parseISO,
    set
} from "date-fns";
import {DatePicker} from "../common/DatePicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {Booking, Discount, MaxTimeRange} from "../../interfaces/interfaces";
import {useFetch} from "../../hooks/useFetch";
import {CustomSkeleton} from "../common/CustomSkeleton";
import {mapToJson, roundedHrs} from "../../helpers/common";
import {ExtendToTimePicker} from "./ExtendToTimePicker";
import {ExtendFromTimePicker} from "./ExtendFromTimePicker";
import {useCountDownTimer} from "../../hooks/useCountDownTimer";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";


const minBookHrs = 2
const discountsURL = '/discount/all'
const checkTimeRangeLimitURL = "/extension/time_range_limit"

export const ExtendDateSelection = ({
                                        booking: originalBooking = {} as Booking,
                                        cardRef = {} as React.RefObject<HTMLDivElement>,
                                        registerRef = {} as React.RefObject<HTMLDivElement>,
                                        height = ['auto'],
                                        goToNext = [] as any
                                    }) => {
    const {t} = useTranslation('dateSelection');
    const {t: tCommon} = useTranslation('common');
    const navigate = useNavigate()
    const toast = useToast()
    const [actualDateTime, setActualDateTime] = useState(set(new Date(), {seconds: 0, milliseconds: 0}))
    const {countDown} = useCountDownTimer(3600)

    const [fromDate, setFromDate] = useState(parseISO(originalBooking.From));
    const [fromTime, setFromTime] = useState(parseISO(originalBooking.From));
    const [toDate, setToDate] = useState(
        isBefore(parseISO(originalBooking.To), new Date()) ?
            actualDateTime :
            parseISO(originalBooking.To));
    const [toTime, setToTime] = useState(
        isBefore(parseISO(originalBooking.To), new Date()) ?
            set(actualDateTime, {
                hours: actualDateTime.getHours() + 1,
                minutes: parseISO(originalBooking.To).getMinutes()
            }) :
            parseISO(originalBooking.To));
    const [now, setNow] = useState(false);
    const [showNowItem, setShowNowItem] = useState(false);

    const [fromMinTime, setFromMinTime] = useState(actualDateTime);
    const [toMaxTime, setToMaxTime] = useState(addMonths(toTime, 12));

    const [from, setFrom] = useState(set(fromDate,
        {hours: fromTime.getHours(), minutes: fromTime.getMinutes()}));

    const [to, setTo] = useState(set(toDate,
        {hours: toTime.getHours(), minutes: toTime.getMinutes()}));

    const {
        data: discounts = {} as Map<string, Discount>,
        fetch: fetchDiscounts, isLoading: loadingDiscounts
    } = useFetch()

    const {
        data: maxTimeRange = {} as MaxTimeRange,
        fetch: fetchCheckTimeRangeLimit
    } = useFetch()

    const getDiscounts = async () => {
        const error = await fetchDiscounts('get', discountsURL, {})
        if (error) {
            if (!toast.isActive("InternalServerError")) {
                toast({
                    id: "InternalServerError",
                    title: tCommon('Errors.InternalServerError'),
                    description: tCommon('Errors.PleaseContactUs'),
                    status: 'error',
                    duration: 10000,
                    isClosable: false,
                })
            }

            navigate("/", {replace: true})
            return
        }
    }

    const checkTimeRangeLimit = async () => {
        const error = await fetchCheckTimeRangeLimit('get', checkTimeRangeLimitURL + '/' +
            originalBooking.ID, {})
        if (error) {
            toast({
                title: 'No se pudo obtener informacion importante desde el servidor!',
                description: "Por favor contáctenos para brindarle la atención correspondiente",
                status: 'error',
                duration: 5000,
                isClosable: false,
            })

            return
        }
    }

    useEffect(() => {
        getDiscounts()
    }, []);

    useEffect(() => {
        if (originalBooking !== undefined && originalBooking.ID) {
            checkTimeRangeLimit()

            const auxList = [] as any
            Object.entries((originalBooking as Booking).Spaces).map(([k, v]) => {
                let descMap = new Map<string, any>()

                Object.entries(v.Size.Description).map(([k, v]) => {
                    descMap.set(k, v)
                })

                let capMap = new Map<string, any>()
                Object.entries(v.Size.Capacity).map(([k, v]) => {
                    capMap.set(k, v)
                })

                const desc = mapToJson(descMap)
                const capacity = mapToJson(capMap)
                const newAux: {
                    ID: string
                    Description: string
                    Type: string
                    Width: number
                    Height: number
                    Depth: number
                    Price: number
                    ViewOrder: number
                    Capacity: string
                    CreatedAt: string
                    UpdatedAt: string
                    SizeQty: number
                } = {...v.Size, Description: desc, Capacity: capacity, SizeQty: 1}

                let add = true
                for (let i = 0; i < auxList.length; i++) {
                    if (auxList[i].ID === newAux.ID) {
                        auxList[i].SizeQty++
                        add = false
                        break
                    }

                }

                if (add) {
                    auxList.push(newAux)
                }
            })

            let acceptTermsAndConditions = ''
            Object.entries((originalBooking as Booking).AdditionalInfo).map(([k, v]) => {
                if (k === 'acceptTermsAndConditions') {
                    acceptTermsAndConditions = v
                }
            })

            localStorage.setItem('selectedSizes', JSON.stringify(auxList))
            localStorage.setItem('acceptTermsAndConditions', acceptTermsAndConditions)
            localStorage.setItem('customerName', originalBooking.Customer.Name)
            localStorage.setItem('customerLastName', originalBooking.Customer.LastName)
            localStorage.setItem('customerEmail', originalBooking.Customer.Email)
            localStorage.setItem('customerPhone', originalBooking.Customer.Phone)
            localStorage.setItem('branch', JSON.stringify(originalBooking.Branch))
            localStorage.setItem('location', originalBooking.Branch.City)
        }

    }, [originalBooking]);

    useEffect(() => {
        if (originalBooking !== undefined && originalBooking.ID) {
            if (maxTimeRange !== undefined && maxTimeRange) {
                if ((maxTimeRange as MaxTimeRange).Backwards != null) {
                    const min = addMinutes(originalBooking?.From, -(maxTimeRange as MaxTimeRange).Backwards)
                    setFromMinTime(min)
                    if (isBefore(min, actualDateTime)) {
                        setFromMinTime(parseISO(originalBooking?.From))
                        setShowNowItem(true)
                    }
                } else {
                    setShowNowItem(true)
                }

                if ((maxTimeRange as MaxTimeRange).Forwards != null) {
                    const max = addMinutes(originalBooking?.To, (maxTimeRange as MaxTimeRange).Forwards)
                    setToMaxTime(max)
                }
            }
        }
    }, [maxTimeRange])

    useEffect(() => {
    }, [fromMinTime, toMaxTime]);

    // Scroll to the section when button is clicked
    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        scrollToSection(registerRef)
    }, []);

    useEffect(() => {
        localStorage.setItem('fromDate', format(fromDate, 'dd-MM-yyyy'))
        localStorage.setItem('fromTime', format(fromTime, 'HH:mm'))
        localStorage.setItem('toDate', format(toDate, 'dd-MM-yyyy'))
        localStorage.setItem('toTime', format(toTime, 'HH:mm'))
        localStorage.setItem('isNowBook', now ? 'true' : 'false');
        setFrom(set(fromDate,
            {hours: fromTime.getHours(), minutes: fromTime.getMinutes()}))

        setTo(set(toDate,
            {hours: toTime.getHours(), minutes: toTime.getMinutes()}))
    }, [fromDate, fromTime, toDate, toTime, now]);

    useEffect(() => {
        const actual = set(new Date(), {seconds: 0, milliseconds: 0})
        setActualDateTime(actual)
    }, [countDown]);

    const handleFromDateChange = (value: Date) => {
        const newTime = set(value, {hours: fromTime.getHours(), minutes: fromTime.getMinutes()})
        setFromDate(newTime);
        setFromTime(newTime);

        if (isAfter(addHours(newTime, 2), toTime)) {
            setToTime(addHours(newTime, minBookHrs))
        }

        if (isFromDateAfterToDate(value)) {
            setToDate(value);
        }

        if (!isToday(value)) {
            const aux = set(value, {hours: fromTime.getHours(), minutes: 0})
            setFromTime(aux);
        }

        if (isToday(value) && isTimeBeforeThanNow(newTime)) {
            setFromTime(actualDateTime);
        }

        if (shouldChangeToDateToNextDay(toDate, newTime)) {
            setToDate(addDays(toDate, 1));
        }
    }

    const handleToDateChange = (value: Date) => {
        const newTime = set(value, {hours: toTime.getHours(), minutes: toTime.getMinutes()})
        setToDate(newTime)
        setToTime(newTime)

        if (isAfter(addHours(fromTime, 2), newTime)) {
            setToTime(addHours(fromTime, minBookHrs))
        }

        if (shouldChangeToDateToNextDay(newTime, fromTime)) {
            setToDate(addDays(newTime, 1));
        }
    }

    const handleFromTimeChange = (value: Date) => {
        const newTime = set(fromDate, {hours: value.getHours(), minutes: value.getMinutes()})
        setFromTime(newTime)

        if (isAfter(addHours(newTime, 2), toTime)) {
            setToTime(addHours(newTime, minBookHrs))
        }

        if (shouldChangeToDateToNextDay(toDate, newTime)) {
            setToDate(addDays(toDate, 1));
        }
    }

    const handleToTimeChange = (value: Date) => {
        const newTime = set(toDate, {hours: value.getHours(), minutes: value.getMinutes()})
        setToTime(newTime)
    }

    const isFromDateAfterToDate = (f: Date) => {
        return isAfter(f, toTime)
    }

    const isTimeBeforeThanNow = (t: Date) => {
        return isBefore(t, new Date())
    }

    const shouldChangeToDateToNextDay = (toDate: Date, t: Date) => {
        const aux = addHours(t, minBookHrs)
        return isSameDay(aux, addDays(toDate, 1))
    }

    const isValidExtension = () => {
        const originalFrom = parseISO(originalBooking.From)
        const originalTo = parseISO(originalBooking.To)

        if (roundedHrs((differenceInMinutes(to, from) -
            differenceInMinutes(originalFrom, originalTo))) <= 0) {
            return false
        }

        if ((isAfter(from, originalFrom) || isEqual(from, originalFrom)) &&
            isEqual(to, originalTo)) {
            return false
        }

        return !isBefore(to, originalTo)
    }

    const toTimeIsAfterTimeNow = () => {
        return originalBooking ? isAfter(originalBooking.To, actualDateTime) : true
    }

    return (
        <Card ref={cardRef} bgColor={'secondary.500'} color={'lightBrand'}
              h={height}
              borderRadius={20} p={4} w={'100%'}>
            <CardHeader>
                <Heading fontSize={[22, 22, 24]}>
                    {t('Title')}
                </Heading>
            </CardHeader>

            <CardBody p={2}>
                <VStack spacing={4}>
                    <VStack spacing={4} w={'100%'}>
                        <Heading fontSize={[14, 14, 16]} w={'100%'} textAlign={'left'}
                                 textTransform='uppercase'>
                            {t('From')}
                        </Heading>
                        <Stack direction={['column', 'column', 'row', 'row']} w={'100%'} justify={'space-around'}>
                            <DatePicker isFromPicker={true}
                                        isExtension={true}
                                        minDate={set(fromMinTime, {hours: 0, minutes: 0, seconds: 0})}
                                        maxDate={set(parseISO(originalBooking.To), {hours: 0, minutes: 0, seconds: 0})}
                                        date={fromDate}
                                        setDate={handleFromDateChange}/>
                            <ExtendFromTimePicker booking={originalBooking}
                                                  showNowItem={showNowItem}
                                                  now={now}
                                                  setNow={setNow}
                                                  minTime={fromMinTime}
                                                  maxTime={parseISO(originalBooking.From)}
                                                  time={fromTime}
                                                  setTime={(t: Date) => handleFromTimeChange(t)}/>
                        </Stack>
                    </VStack>
                    <Divider/>
                    <VStack spacing={4} w={'100%'}>
                        <HStack w={'100%'}>
                            <Heading fontSize={[14, 14, 16]} me={4} textAlign={'left'}
                                     textTransform='uppercase'>
                                {t('To')}
                            </Heading>
                        </HStack>
                        <Stack direction={['column', 'column', 'row', 'row']} w={'100%'} justify={'space-around'}>
                            <DatePicker isExtension={true}
                                        minDate={!toTimeIsAfterTimeNow() ? set(actualDateTime, {
                                                hours: 0,
                                                minutes: 0,
                                                seconds: 0
                                            })
                                            : set(parseISO(originalBooking.From), {
                                                hours: 0,
                                                minutes: 0,
                                                seconds: 0
                                            })}
                                        maxDate={set(toMaxTime, {hours: 0, minutes: 0, seconds: 0})}
                                        date={toDate} setDate={handleToDateChange}/>
                            <ExtendToTimePicker
                                minTime={!toTimeIsAfterTimeNow() ? actualDateTime : parseISO(originalBooking.To)}
                                maxTime={toMaxTime}
                                fromTime={fromTime}
                                time={toTime}
                                discounts={discounts}
                                setTime={handleToTimeChange}/>
                        </Stack>
                    </VStack>
                    <Divider/>
                    <VStack spacing={4} w={'100%'}>
                        <Heading fontSize={[14, 14, 16]} w={'100%'} textAlign={'left'}
                                 textTransform='uppercase'>
                            {t('Promotions')}
                        </Heading>
                        <Stack direction={['column', 'column', 'row']} w={'100%'} textAlign={'left'}>
                            <CustomSkeleton height={'88'} isLoaded={!loadingDiscounts}>
                                <UnorderedList spacing={2} w={'100%'}>
                                    {
                                        Object.entries(discounts as Discount[]).map(([key, d]) => (
                                            <ListItem key={key}>{t('MoreThan')}{d.MinHours}hrs. - {d.Percent}%
                                                off</ListItem>
                                        ))
                                    }
                                </UnorderedList>
                            </CustomSkeleton>
                        </Stack>
                    </VStack>
                    <Divider/>
                    <HStack spacing={2} w={'100%'}>
                        <FontAwesomeIcon fontSize={22} icon={faCircleInfo}/>
                        <Text fontSize={[14, 16]}>
                            {t('ExtensionSubjectToAvailability')}
                        </Text>
                    </HStack>
                    <Collapse in={!isValidExtension()} animateOpacity>
                        <Divider/>
                        <HStack spacing={2} w={'100%'} my={4}>
                            <FontAwesomeIcon fontSize={22} color={'tomato'} icon={faCircleExclamation}/>
                            <Text fontSize={[14, 16]} textAlign={'left'} color={'tomato'}>
                                {t('SelectTimeRangeGraterThanOriginal')}
                            </Text>
                        </HStack>
                    </Collapse>
                    <HStack w={'100%'} justifyContent={'center'} position={'relative'} bottom={0}>
                        <NextBtn
                            isDisabled={!isValidExtension()}
                            nextStep={goToNext}/>
                    </HStack>
                </VStack>
            </CardBody>
        </Card>
    );
}
