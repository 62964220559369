import {Box, Center, CircularProgress, Fade, Heading, Image, VStack} from "@chakra-ui/react";
import logo from "../../assets/img/lockers.webp";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {Booking} from "../../interfaces/interfaces";
import {useFetch} from "../../hooks/useFetch";
import {HTTPErrorCodeBookingInvalidForPayment} from "../../common/constants";
import {PayPenaltyDetail} from "./PayPenaltyDetail";
import {BookingInvalidStateAlert} from "../common/BookingInvalidStateAlert";
import {BookingFinalizedAlert} from "../common/BookingFinalizedAlert";
import {validateState} from "../../helpers/states_validation";
import {useTranslation} from "react-i18next";

const checkValidForPaymentURL = "/payment/penalty/check/"

export const PenaltyRegister = () => {
    const {t: tCommon, i18n: {language}} = useTranslation('common')
    const registerHeadingRef = useRef(null);
    const cardsRef = useRef<HTMLDivElement>(null);
    const [registerHeight, setRegisterHeight] = useState(600);
    const {bookingID} = useParams()
    const [errors, setErrors] =
        useState<Map<string, string>>(new Map());

    const {
        data: bookingToPay = {} as Booking,
        fetch: fetchBookingToPay, isLoading: loadingBookingToPay
    } = useFetch()


    const getBooking = async () => {
        const error = await fetchBookingToPay("get", checkValidForPaymentURL + bookingID, {})

        setErrors(validateState(error?.response?.data?.Code, language))
    }

    useEffect(() => {
        getBooking()
    }, []);

    useEffect(() => {
        if (!loadingBookingToPay && cardsRef.current) {
            if (cardsRef && cardsRef.current) {
                setRegisterHeight(cardsRef.current.getBoundingClientRect().height);
            }

            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    setRegisterHeight(entry.contentRect.height); // Update parent's height based on the active child
                }
            });

            if (cardsRef.current) {
                resizeObserver.observe(cardsRef.current); // Observe changes in the active child
            }

            return () => {
                resizeObserver.disconnect(); // Cleanup observer on unmount
            };
        }
    }, [loadingBookingToPay, cardsRef.current]);

    return (
        <Box id={'book_register'} position="relative" color={'lightBrand'}
             height={errors.size !== 0 ? registerHeight : registerHeight + 300} w={'100%'}
             transition="height 0.5s ease">
            <Center w={'100%'}>
                <Image draggable={false} src={logo} w={'100%'}
                       height={errors.size !== 0 ? registerHeight : registerHeight + 300} objectFit={'cover'}/>
            </Center>
            <Box
                position="absolute"
                top={0}
                left={0}
                width={'100%'}
                height={'100%'}
                opacity={0.8} bgColor={'primary.500'}
            />

            <Fade in={true} transition={{enter: {duration: 0.5}}}>
                <VStack
                    id={'vstack_register'}
                    position={'absolute'}
                    top={errors.size !== 0 ? 280 : 650}
                    left="50%"
                    transform="translate(-50%, -50%)"
                    textAlign="center"
                    w={'90%'}
                    justify={'center'}
                    m={'auto'}>
                    <Heading ref={registerHeadingRef} textAlign={'center'} fontSize={[30, 36, 44]} mb={4}
                             textTransform='uppercase'>
                        {tCommon('PayPenaltyTitle')}
                    </Heading>
                    <VStack w={'100%'} id={'slide_container_vstack'}>
                        {
                            Array.isArray(bookingToPay) || loadingBookingToPay ?
                                <Center mt={10}>
                                    <CircularProgress isIndeterminate color={'secondary.500'}/>
                                </Center>
                                :
                                errors.size !== 0 && !errors.has(HTTPErrorCodeBookingInvalidForPayment) ?
                                    <BookingInvalidStateAlert errors={errors}/>
                                    :
                                    errors.has(HTTPErrorCodeBookingInvalidForPayment) ?
                                        <BookingFinalizedAlert errors={errors}/>
                                        :
                                        <VStack width={['100%', '90%', '60%', '50%', '40%', '35%']}
                                                id={'vstack_stepper'}>
                                            <PayPenaltyDetail bookingToPay={bookingToPay} cardRef={cardsRef}
                                                              registerRef={registerHeadingRef}/>
                                        </VStack>
                        }
                    </VStack>
                </VStack>
            </Fade>
        </Box>
    );
};