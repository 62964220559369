import {isAfter, isBefore, isEqual, set} from "date-fns";
import React from "react";
import {Discount} from "../../interfaces/interfaces";
import {TimePicker} from "../common/TimePicker";


export const ExtendToTimePicker = ({
                                       minTime = set(new Date(), {seconds: 0, milliseconds: 0}),
                                       maxTime = set(new Date(), {seconds: 0, milliseconds: 0}),
                                       fromTime = set(new Date(), {seconds: 0, milliseconds: 0}),
                                       time = set(new Date(), {seconds: 0, milliseconds: 0}),
                                       discounts = {} as Map<string, Discount> | never[],
                                       setTime = [] as any
                                   }) => {
    let hrs = Array.from(Array(24).keys())

    hrs = hrs.filter((v) => {
        const hrTime = set(time, {hours: v, milliseconds: 0})
        const min = set(minTime, {milliseconds: 0})
        const max = set(maxTime, {milliseconds: 0})

        return (isEqual(hrTime, min) || isAfter(hrTime, min)) && (isEqual(hrTime, max) || isBefore(hrTime, max))
    })

    return (
        <TimePicker isFromPicker={false}
                    isExtension={true}
                    time={time}
                    setTime={setTime}
                    fromTime={fromTime}
                    hrs={hrs}
                    discounts={discounts}
        />
    )
}