import {
    Box,
    Button,
    Collapse,
    Divider,
    Fade,
    Heading,
    HStack,
    Text,
    useDisclosure,
    VStack,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";


export const FAQ = () => {
    const {t} = useTranslation('landing');
    return (
        <>
            <VStack id={'faq'} position={'relative'} color={'lightBrand'} w={'100%'} bgColor={'secondary.500'}
                    justify='space-evenly' align='center' py={42} pb={16}>
                <Fade in={true} transition={{enter: {duration: 0.5}}}>
                    <Heading fontSize={30} textAlign={'center'} fontWeight={'bold'} color={'primary.500'} mb={5}>
                        {t('FAQTitle')}
                    </Heading>
                </Fade>

                <HStack justify={'center'} w={'100%'}>
                    <Fade in={true} transition={{enter: {duration: 0.5}}}>
                        <Wrap justify={'center'} spacing={[0, 0, 5, 10]} w={'100%'}>
                            <Item num={1}
                                  question={t('QuestionOne')}
                                  answer={t('AnswerOne')}/>
                            <Item num={2}
                                  question={t('QuestionTwo')}
                                  answer={t('AnswerTwo')}/>
                        </Wrap>
                    </Fade>
                </HStack>
                <HStack justify={'center'}>
                    <Fade in={true} transition={{enter: {duration: 0.5}}}>
                        <Wrap justify={'center'} spacing={[0, 0, 5, 10]} id={'wrappp?'}>
                            <Item num={3}
                                  question={t('QuestionThree')}
                                  answer={t('AnswerThree')}/>


                            <Item num={4}
                                  question={t('QuestionFour')}
                                  answer={t('AnswerFour')}/>
                        </Wrap>
                    </Fade>
                </HStack>
                <HStack justify={'center'}>
                    <Fade in={true} transition={{enter: {duration: 0.5}}}>
                        <Wrap justify={'center'} spacing={[0, 0, 5, 10]} w={'100%'}>
                            <Item num={5}
                                  question={t('QuestionFive')}
                                  answer={t('AnswerFive')}/>
                            <Item num={6}
                                  question={t('QuestionSix')}
                                  answer={t('AnswerSix')}/>
                        </Wrap>
                    </Fade>
                </HStack>


            </VStack>
        </>
    );
};

const Item = ({num = 1, question = '', answer = ''}) => {
    const {isOpen, onToggle} = useDisclosure()
    return (
        <>
            <WrapItem w={[300, 400, 500, 600]} mt={3}>
                <VStack align={'left'} w={'100%'}>
                    <Divider bgColor={'primary.500'} height='1px' borderColor={'transparent'}
                             borderRadius={5} w={'100%'}/>
                    <HStack mt={3} w={'100%'}>
                        <Heading fontSize={[24, 26, 30]}>{num}.</Heading>
                        <Button onClick={onToggle}
                                variant={'ghost'}
                                _hover={{bgColor: 'secondary.500', color: 'white'}}
                                fontSize={[16, 18, 20]}
                                fontWeight={'bold'}
                                ps={2}
                                textAlign={'left'}
                                wordBreak={'break-word'}
                                whiteSpace={'normal'}
                                color={'primary.500'}>
                            {question}
                        </Button>
                    </HStack>
                    <Collapse in={isOpen} animateOpacity>
                        <Box p={2}
                             color={'lightBrand'}
                             opacity={0.7}
                             fontSize={16}>
                            <Text overflowWrap={'break-word'} fontSize={[14, 16, 18]}>
                                {answer}
                            </Text>
                        </Box>
                    </Collapse>
                </VStack>
            </WrapItem>
        </>
    )
}