import {Box, Center, Fade, Heading, Image, VStack} from "@chakra-ui/react";
import logo from "../../assets/img/lockers.webp";
import React, {useEffect, useRef, useState} from "react";

import {RentSlider} from "./RentSlider";
import {useTranslation} from "react-i18next";

export const RentRegister = () => {
    const {t:tCommon} = useTranslation('common');
    const registerHeadingRef = useRef(null);
    const registerBoxRef = useRef(null);
    const cardsRef = useRef<HTMLDivElement>(null);
    const [registerHeight, setRegisterHeight] = useState(500);
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (cardsRef && cardsRef.current) {
            setRegisterHeight(cardsRef.current.getBoundingClientRect().height);
        }

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setRegisterHeight(entry.contentRect.height); // Update parent's height based on the active child
            }
        });

        if (cardsRef.current) {
            resizeObserver.observe(cardsRef.current); // Observe changes in the active child
        }

        return () => {
            resizeObserver.disconnect(); // Cleanup observer on unmount
        };
    }, [activeStep]);


    const handleActiveStep = (value: number) => {
        setActiveStep(value);
    }

    return (
        <Box ref={registerBoxRef} id={'book_register'} position="relative" color={'lightBrand'}
             height={registerHeight + 350}  w={'100%'} transition="height 0.5s ease">
            <Center w={'100%'}>
                <Image draggable={false} src={logo} w={'100%'}
                       height={registerHeight + 350} objectFit={'cover'}/>
            </Center>
            <Box
                position="absolute"
                top={0}
                left={0}
                width={'100%'}
                height={'100%'}
                opacity={0.8} bgColor={'primary.500'}
            />

            <Fade in={true} transition={{enter: {duration: 0.5}}}>
                <VStack
                    id={'vstack_register'}
                    position={'absolute'}
                    top={200}
                    left="50%"
                    transform="translate(-50%, -50%)"
                    textAlign="center"
                    w={'90%'}
                    justify={'center'}
                    m={'auto'}>
                    <Heading ref={registerHeadingRef} textAlign={'center'} fontSize={[30, 36, 44]} mb={4} textTransform='uppercase'>
                        {tCommon('NewRentTitle')}
                    </Heading>
                    <VStack w={'100%'} id={'slide_container_vstack'}>
                        <RentSlider setActiveStep={handleActiveStep}
                                    cardRef={cardsRef}
                                    registerBoxRef={registerBoxRef}
                                    registerHeadingRef={registerHeadingRef} />
                    </VStack>
                </VStack>
            </Fade>
        </Box>
    );
};