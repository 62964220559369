import {Header} from "../components/common/Header";
import {Footer} from "../components/common/Footer";
import {PenaltyRegister} from "../components/penalty_process/PenaltyRegister";

export const Penalty = () => {
    return (
        <>
            <Header/>
            <PenaltyRegister/>
            <Footer/>
        </>
    )
}