import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import headerES from './locales/header/es.json'
import headerEN from './locales/header/en.json'
import headerPT from './locales/header/pt.json'
import footerES from './locales/footer/es.json'
import footerEN from './locales/footer/en.json'
import footerPT from './locales/footer/pt.json'
import customerES from './locales/customer/es.json'
import customerEN from './locales/customer/en.json'
import customerPT from './locales/customer/pt.json'
import landingES from './locales/landing/es.json'
import landingEN from './locales/landing/en.json'
import landingPT from './locales/landing/pt.json'
import locationES from './locales/location/es.json'
import locationEN from './locales/location/en.json'
import locationPT from './locales/location/pt.json'
import dateSelectionES from './locales/date_selection/es.json'
import dateSelectionEN from './locales/date_selection/en.json'
import dateSelectionPT from './locales/date_selection/pt.json'
import sizesES from './locales/sizes/es.json'
import sizesEN from './locales/sizes/en.json'
import sizesPT from './locales/sizes/pt.json'
import detailES from './locales/detail/es.json'
import detailEN from './locales/detail/en.json'
import detailPT from './locales/detail/pt.json'
import commonES from './locales/common/es.json'
import commonEN from './locales/common/en.json'
import commonPT from './locales/common/pt.json'
import paymentES from './locales/payment/es.json'
import paymentEN from './locales/payment/en.json'
import paymentPT from './locales/payment/pt.json'

// Function to detect the system language
const detectSystemLanguage = (): 'es' | 'en' | 'pt' => {
    const systemLang = navigator.language || navigator.languages[0];  // Detect language

    // Check if the detected language is 'es', 'en' or 'pt'
    if (systemLang.startsWith('es')) {
        return 'es';
    } else if (systemLang.startsWith('pt')) {
        return 'pt';
    } else {
        return 'en'; // Default to 'en' if the language is not 'es'
    }
};

// Save language in localStorage if not already set
const savedLanguage = localStorage.getItem('i18nextLng');
if (!savedLanguage) {
    const detectedLanguage = detectSystemLanguage();
    localStorage.setItem('i18nextLng', detectedLanguage);
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                header: headerEN,
                landing: landingEN,
                footer: footerEN,
                customer: customerEN,
                location: locationEN,
                dateSelection: dateSelectionEN,
                sizes: sizesEN,
                detail: detailEN,
                common: commonEN,
                payment: paymentEN,
            },
            es: {
                header: headerES,
                landing: landingES,
                footer: footerES,
                customer: customerES,
                location: locationES,
                dateSelection: dateSelectionES,
                sizes: sizesES,
                detail: detailES,
                common: commonES,
                payment: paymentES,
            },
            pt: {
                header: headerPT,
                landing: landingPT,
                footer: footerPT,
                customer: customerPT,
                location: locationPT,
                dateSelection: dateSelectionPT,
                sizes: sizesPT,
                detail: detailPT,
                common: commonPT,
                payment: paymentPT,
            }
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'],
        },
        debug: false,
    });