import axios from "axios";
import {CustomerToken} from "../store/auth/auth";
// @ts-ignore
import UAParser from 'ua-parser-js';

export const ServiceURL = process.env.REACT_APP_SERVICE_URL

export const api = axios.create({
    baseURL: `${ServiceURL}`,
    timeout: 20000,
})

const requestHandler = (req: any) => {

    req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    if (localStorage.getItem("token") == null) {
        req.headers.Authorization = `Bearer ${CustomerToken}`
    }

    if (localStorage.getItem("X-Rent-Sha")) {
        req.headers["x-rent-sha"] = localStorage.getItem("X-Rent-Sha")
    }

    const info =  getClientInfo()

    req.headers["client-info"] = JSON.stringify(info)

    return req
}

const getClientInfo = () => {
    const parser = new UAParser();
    const result = parser.getResult();

     return {
        Browser: result.browser.name,
        BrowserVersion: result.browser.version,
        OS: result.os.name,
        OSVersion: result.os.version,
        DeviceType: result.device.type || "Desktop",
        DeviceModel: result.device.model || "Unknown",
    }
}

export const statusUnauthorized = 401
export const statusForbidden = 403
export const statusInternalServerError = 500

const responseHandler = (res: any) => {
    if (res.headers["x-rent-sha"]) {
        localStorage.setItem("X-Rent-Sha", res.headers["x-rent-sha"])
    }
    return res;
}

api.interceptors.request.use(
    (request) => requestHandler(request),
    (error) =>  {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => {
        if (error.response &&
            error.response.status === statusForbidden) {
           window.location.assign('/')
        }

        if (error.response &&
            error.response.status === statusInternalServerError) {
            window.location.assign('')
        }

        return Promise.reject(error)
    }
)
