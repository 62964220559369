import {Box, Center, Fade, Heading, Image, VStack} from "@chakra-ui/react";
import logo from "../../assets/img/lockers.webp";
import React from "react";
import {RentBtn} from "./RentBtn";
import {useTranslation} from "react-i18next";


export const Enjoy = () => {
    const {t} = useTranslation('landing');
    return (

        <Box position="relative" color={'lightBrand'} height={'100vh'} w={'100%'}>
            <Center w={'100%'}>
                <Image draggable={false} src={logo} w={'100%'}
                       height={'100vh'} objectFit={'cover'}/>
            </Center>
            <Box
                position="absolute"
                top={0}
                left={0}
                width={'100%'}
                height={'100%'}
                opacity={0.8} bgColor={'primary.500'}
            />

            <VStack position={'absolute'}
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    textAlign="center"
                    w={'100%'}
                    px={'10%'}
                    justify={'center'}
                    m={'auto'}>
                <Heading textAlign={'center'} fontSize={[30, 42, 54]} w={['100%', '100%', '90%', '70%']}>
                    {t('SloganLong')}
                </Heading>
                <Fade in={true} transition={{enter: {duration: 0.5}}}>
                    <Center mt={10}>
                        <RentBtn/>
                    </Center>
                </Fade>
            </VStack>
        </Box>
    );
};