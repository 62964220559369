import {Location} from "./Location";
import {DateSelection} from "./DateSelection";
import {Sizes} from "./Sizes";
import {Customer} from "./Customer";
import {RentDetail} from "./RentDetail";
import {RefObject} from "react";

export const RentSteps = ({
                              cardRef = {} as RefObject<HTMLDivElement>,
                              registerBoxRef = {} as RefObject<HTMLDivElement>,
                              registerHeadingRef = {} as RefObject<HTMLDivElement>,
                              height = ['auto'],
                              step = 0,
                              goToPrev = [] as any,
                              goToNext = [] as any
                          }): JSX.Element | null => {

    switch (step) {
        case 0:
            return <Location cardRef={cardRef} registerBoxRef={registerBoxRef} height={height} goToNext={goToNext}/>
        case 1:
            return <DateSelection cardRef={cardRef} registerRef={registerHeadingRef} height={height} goToPrev={goToPrev}
                                  goToNext={goToNext}/>
        case 2:
            return <Sizes cardRef={cardRef} registerRef={registerHeadingRef} height={height} goToPrev={goToPrev}
                          goToNext={goToNext}/>
        case 3:
            return <Customer cardRef={cardRef} registerRef={registerHeadingRef} height={height} goToPrev={goToPrev}
                             goToNext={goToNext}/>
        case 4:
            return <RentDetail cardRef={cardRef} registerRef={registerHeadingRef} height={height} goToPrev={goToPrev}/>
    }

    return null;
}