import {useEffect, useState} from "react";

export const useDebounce = (delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState('');

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(debouncedValue);
        }, delay);

        return () => {
            clearTimeout(handler);  // Clear the timeout on value or delay change
        };
    }, [debouncedValue, delay]);

    return {debouncedValue, setDebouncedValue};
}
