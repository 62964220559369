export const validate = (inputName: string, language: string, value: string, prevEmail?: string): Map<string, string> => {
    const errMap: Map<string, string> = new Map();
    for (const key in validations) {
        if (inputName !== key) {
            continue
        }

        const validation = validations[key]
        if (validation?.required?.value && !value) {
            errMap.set(key, validation?.required[language])
            continue
        }

        if (validation?.pattern?.value && !RegExp(validation?.pattern.value).test(value)) {
            errMap.set(key, validation?.pattern[language])
            continue
        }

        if (value && validation?.custom?.isValid && !validation?.custom.isValid(value.toString())) {
            errMap.set(key, validation?.custom[language])
        }

        if ((value && prevEmail) && validation?.custom?.match && !validation?.custom.match(value.toLowerCase(), prevEmail.toLowerCase())) {
            errMap.set(key, validation?.custom[language])
        }
    }

    return new Map(errMap)
}

//Key Modifications:
//
// 	•	Added start (^) and end ($) anchors to ensure full string matching.
// 	•	Allowed uppercase letters in both the local and domain parts (A-Z).
// 	•	Restricted the TLD part to be at least 2 characters long with [a-zA-Z]{2,}.
const emailRegex = /^[ña-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[ña-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[ña-zA-Z0-9](?:[ña-zA-Z0-9-]*[ña-zA-Z0-9])?\.)+[ña-zA-Z]{2,}$/

const validations: any = {
    name: {
        required: {
            value: true,
            es: "El nombre es requerido",
            en: "The name is required",
            pt: "O nome é obrigatório"
        },
        pattern: {
            value: /^[\p{Letter}\s\-.']+$/u,
            es: "Por favor, ingrese un nombre válido",
            en: "Please enter a valid name",
            pt: "Por favor, insira um nome válido"
        }
    },
    lastName: {
        required: {
            value: true,
            es: "El apellido es requerido",
            en: "The last name is required",
            pt: "O sobrenome é obrigatório"
        },
        pattern: {
            value: /^[\p{Letter}\s\-.']+$/u,
            es: "Por favor, ingrese un apellido válido",
            en: "Please enter a valid last name",
            pt: "Por favor, insira um sobrenome válido"
        }
    },
    phone: {
        required: {
            value: true,
            es: "El teléfono es requerido",
            en: "The phone is required",
            pt: "O telefone é obrigatório"
        },
        custom: {
            isValid: (value: any) => value?.length >= 8,
            es: "El teléfono debe tener al menos 8 dígitos",
            en: "The phone must have at least 8 digits",
            pt: "O telefone deve ter pelo menos 8 dígitos"
        }
    },
    email: {
        required: {
            value: true,
            es: "El correo electrónico es requerido",
            en: "The email address is required",
            pt: "O endereço de e-mail é obrigatório"
        },
        pattern: {
            value: emailRegex,
            es: "Por favor, ingrese un correo electrónico válido. ej.: 'juan.perez@mail.com'",
            en: "Please enter a valid email address (e.g., ‘john.doe@mail.com’)",
            pt: "Por favor, insira um endereço de e-mail válido (ex.: ‘joao.silva@mail.com’)"
        }
    },
    discoveryMethod: {
        required: {
            value: true,
            es: "Debes seleccionar una opción para saber cómo nos encontraste",
            en: "You must select an option to let us know how you found us",
            pt: "Você deve selecionar uma opção para nos informar como nos encontrou"
        }
    },
    confirmationEmail: {
        required: {
            value: true,
            es: "El correo electrónico es requerido",
            en: "The email address is required",
            pt: "O endereço de e-mail é obrigatório"
        },
        pattern: {
            value: emailRegex,
            es: "Por favor, ingrese un correo electrónico válido. ej.: 'juan.perez@mail.com'",
            en: "Please enter a valid email address (e.g., ‘john.doe@mail.com’)",
            pt: "Por favor, insira um endereço de e-mail válido (ex.: ‘joao.silva@mail.com’)"
        },
        custom: {
            match: (email: string, prevEmail: string) => email === prevEmail,
            es: "El correo electrónico debe coincidir con el ingresado anteriormente",
            en: "The email address must match the one entered previously",
            pt: "O endereço de e-mail deve coincidir com o inserido anteriormente"
        }
    }
}

