import {useEffect, useState} from "react";

export const useCountDownTimer = (secs: number = Number(process.env.REACT_APP_USER_SESSION_SECS)) => {
    const [countDown, setCountDown] = useState(secs);

    useEffect(() => {
        if (countDown === 0) {
            return;  // If countdown is zero, don't start the interval
        }

        const interval = setInterval(() => {
            setCountDown((prevCountDown) => {
                if (prevCountDown <= 1) {  // Stop when countdown reaches zero
                    clearInterval(interval);
                    return 0;
                }
                return prevCountDown - 1;
            });
        }, 1000);

        return () => clearInterval(interval);  // Clean up interval on unmount
    }, []);

    return {countDown, setCountDown};
};