import {
    HTTPErrorCodeBookingAlreadyPaid,
    HTTPErrorCodeBookingInvalidForPayment, HTTPErrorCodeBookingInvalidOrExpired,
    HTTPErrorCodeBookingPaymentInProgress, HTTPErrorCodeTimeExceededAlreadyPaid,
    HTTPErrorCodeTimeExceededPaymentInProgress,
    HTTPErrorCodeExtensionAlreadyPaid,
    HTTPErrorCodeExtensionPaymentInProgress, HTTPErrorCodePenaltyAlreadyPaid,
    HTTPErrorCodePenaltyPaymentInProgress
} from "../common/constants";

export const validateState = (code: string, language: string): Map<string, string>  => {
    const errMap: Map<string, string> = new Map();

    if (code === HTTPErrorCodeBookingAlreadyPaid) {
        errMap.set(code, stateMsgs.HTTPErrorCodeBookingAlreadyPaid[language])
    }

    if (code === HTTPErrorCodeBookingPaymentInProgress) {
        errMap.set(code, stateMsgs.HTTPErrorCodeBookingPaymentInProgress[language])
    }

    if (code === HTTPErrorCodeExtensionAlreadyPaid) {
        errMap.set(code, stateMsgs.HTTPErrorCodeExtensionAlreadyPaid[language])
    }

    if (code === HTTPErrorCodeExtensionPaymentInProgress) {
        errMap.set(code, stateMsgs.HTTPErrorCodeExtensionPaymentInProgress[language])
    }

    if (code === HTTPErrorCodeTimeExceededAlreadyPaid) {
        errMap.set(code, stateMsgs.HTTPErrorCodeTimeExceededAlreadyPaid[language])
    }

    if (code === HTTPErrorCodeTimeExceededPaymentInProgress) {
        errMap.set(code, stateMsgs.HTTPErrorCodeTimeExceededPaymentInProgress[language])
    }

    if (code === HTTPErrorCodePenaltyAlreadyPaid) {
        errMap.set(code, stateMsgs.HTTPErrorCodePenaltyAlreadyPaid[language])
    }

    if (code === HTTPErrorCodePenaltyPaymentInProgress) {
        errMap.set(code, stateMsgs.HTTPErrorCodePenaltyPaymentInProgress[language])
    }

    if (code === HTTPErrorCodeBookingInvalidForPayment) {
        errMap.set(code, stateMsgs.HTTPErrorCodeBookingInvalidForPayment[language])
    }

    if (code === HTTPErrorCodeBookingInvalidOrExpired) {
        errMap.set(code, stateMsgs.HTTPErrorCodeBookingInvalidOrExpired[language])
    }

    return new Map(errMap)
}

const stateMsgs: any = {
    HTTPErrorCodeBookingAlreadyPaid: {
        es: "¡La reserva ya se encuentra pagada!",
        en: "The reservation is already paid!",
        pt: "A reserva já está paga!"
    },
    HTTPErrorCodeBookingPaymentInProgress: {
        es: "¡La reserva está en proceso de pago!",
        en: "The reservation is in the payment process!",
        pt: "A reserva está em processo de pagamento!"
    },
    HTTPErrorCodeExtensionAlreadyPaid: {
        es: "¡La extensión ya se encuentra pagada!",
        en: "The extension is already paid!",
        pt: "A extensão já está paga!"
    },
    HTTPErrorCodeExtensionPaymentInProgress: {
        es: "¡La extensión está en proceso de pago!",
        en: "The extension is in the payment process!",
        pt: "A extensão está em processo de pagamento!"
    },
    HTTPErrorCodeTimeExceededAlreadyPaid: {
        es: "¡El tiempo excedido ya se encuentra pagado!",
        en: "The exceeded time is already paid!",
        pt: "O tempo excedido já está pago!"
    },
    HTTPErrorCodeTimeExceededPaymentInProgress: {
        es: "¡El tiempo excedido está en proceso de pago!",
        en: "The exceeded time is in the payment process!",
        pt: "O tempo excedido está em processo de pagamento!"
    },
    HTTPErrorCodePenaltyAlreadyPaid: {
        es: "¡La penalidad ya se encuentra pagada!",
        en: "The penalty is already paid!",
        pt: "A penalidade já está paga!"
    },
    HTTPErrorCodePenaltyPaymentInProgress: {
        es: "¡La penalidad está en proceso de pago!",
        en: "The penalty is in the payment process!",
        pt: "A penalidade está em processo de pagamento!"
    },
    HTTPErrorCodeBookingInvalidForPayment: {
        es: "¡La reserva se encuentra finalizada!",
        en: "The reservation is finalized!",
        pt: "A reserva está finalizada!"
    },
    HTTPErrorCodeBookingInvalidOrExpired: {
        es: "¡Reserva inválida o expirada!",
        en: "Invalid or expired reservation!",
        pt: "Reserva inválida ou expirada!"
    }
}